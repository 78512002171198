import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {  Space, Popconfirm } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../redux/store";
import { deleteStoreById, setSelectedRow} from "../../redux/store/reducer";
import './Custom.css';
import { Toaster } from '../../components/common/Toaster/Toaster';
import {dateSorter} from '../../utils/tableSorter';
import { formatDate } from '../../utils/formatDate';

const truncateName = (name: string, limit = 80) => {
    if (name.length > limit) {
        return name.substring(0, limit) + '...';
    }
    return name;
};

export const useStoreColumns = () => {

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    
    const handleUpdateStore = (id: number) => {
        navigate(`/store/` + id, { replace: true });
    };

    const handleDeleteStore = async (id: number) => {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, 2000);
        });
        await dispatch(deleteStoreById(id));
        await Toaster('Successfully Deleted Store', 'success');
    };

    return [
        {
            title: 'Code',
            dataIndex: 'code',
            sorter: (a: any, b: any) => a.code.localeCompare(b.code)
        },
        {
            title: 'Store Name',
            dataIndex: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name)
        },
        {
            title: 'City/Municipality',
            dataIndex: 'city',
            sorter: (a: any, b: any) => a.city.localeCompare(b.city)
        },
        {
            title: 'Detailed Address',
            dataIndex: 'address',
            sorter: (a: any, b: any) => a.address.localeCompare(b.address)
        },
        {
            title: 'Updated/Edited',
            dataIndex: 'createdAt',
            sorter: dateSorter('createdAt'),
            render: (createdAt: any) => (
                <p style={{width: '90px'}}>
                    {formatDate(createdAt)}
                </p>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_: any, record: { key: number }) => (
                <Space>
                    <FormOutlined onClick={() => handleUpdateStore(record.key)} />
                    <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete this item?"
                        onConfirm={() => handleDeleteStore(record.key)}
                        okText="Delete"
                        onVisibleChange={(visible) => {
                            if (visible) {
                                dispatch(setSelectedRow(record.key));
                            } else {
                                dispatch(setSelectedRow(0));
                            }
                        }}
                        cancelText="Cancel">
                        <DeleteOutlined  />
                    </Popconfirm>
                </Space>
            )
        },
        
    ];
};

// Transform data to match column definitions

  