export const usersData = [
    {
        "id": 1,
        "email": "diy.ryan@yopmail.com",
        "firstName": "Ryan Joseph",
        "lastName": "Alegre",
        "password": "Pogi@1234"
    },

    {
        "id": 2,
        "email": "diy.paul@yopmail.com",
        "firstName": "Paul Christian",
        "lastName": "Argao",
        "password": "Pogi@1234"
    }
]

export const bannerCategory = [
    {
        "id": 1,
        "description": "option1",
    },

    {
        "id": 2,
        "description": "option2",
    
    }
]

export const Role = [
    {
        "id": 1,
        "description": "Marketing",
    },
    {
        "id": 2,
        "description": "Accountant",
    }
]

export const ProductCategory = [
    {
        "id": 1,
        "description": "Drills & Bit Set",
    },
    {
        "id": 2,
        "description": "Placeholder Category",
    }
]