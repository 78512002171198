
import React from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useLayout } from './LayoutContext';
import { Avatar, Button, Dropdown, Layout, Row, Col } from 'antd';
import { useAuth } from '../AuthProvider/AuthProvider';
import Buttons from '../../Buttons/Buttons';
const { Header } = Layout;

const HeaderMain = () => {
    const layout = useLayout();
    const auth = useAuth();

    const menu = (
        <div>
            <Buttons title="Logout" onClick={() => auth?.logout()} />
        </div>
    );

    return(
        <Header style={{ padding: 0, background: layout.colorBgContainers, paddingRight: '20px' }}>
            <Row align="middle" justify="space-between">
                <Col>
                    <Button
                        type="text"
                        icon={layout.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => layout.setCollapsed(!layout.collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                </Col>
                <Col>
                    <Dropdown overlay={menu} placement="bottomRight">
                        <div>
                            <Row align="middle" gutter={[16, 16]} >
                                <Col>
                                    <span style={{ fontSize: '14px' }}>Hi! {auth?.authUser?.firstName}</span>
                                </Col>
                                <Col>
                                    <Avatar shape="circle" size={32} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                </Col>
                            </Row>
                        </div>
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    )
}

export default HeaderMain;

