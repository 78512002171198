import { message } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error' | 'loading';

export const Toaster = (description: string, types: NotificationType) => {
    switch(types) {
        case 'success':
            message.success(`${description}`);
            break;
        case 'info':
            message.info(`${description}`);
            break;
        case 'warning':
            message.warning(`${description}`);
            break;
        case 'error':
            message.error(`${description}`);
            break;
        case 'error':
            message.error(`${description}`);
            break;
        default:
            message.info(`${description}`);
            break;
    }
};
