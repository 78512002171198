import { useState } from "react";
import { LockFilled, LeftCircleOutlined } from '@ant-design/icons';
import Buttons from '../../components/Buttons/Buttons';
import { Stack } from '@mui/material';
import './Custom.css';
import Styled from 'styled-components';
import DIYLogo from '../../assets/images/diy_main_logo.png';
import TextBoxPassword from "../../components/common/LoginForms/TextBoxPassword";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import AppModalNotifications from "../../components/Modals/AppModalNotifcation";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [modalOpen, setModelOpen] = useState(false);
  
  const handlePasswordValidation = () => {
    let isValid = true;

    // Check if new password is empty
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasDigit = /\d/.test(newPassword);
    const hasSpecialChar = /[@$!%*?&]/.test(newPassword);
    
    if (newPassword === '') {
      setNewPasswordError('New password is required');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    } else if (newPassword.length < minLength) {
      setNewPasswordError('Password must be at least 8 characters long');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    } else if (!hasUppercase) {
      setNewPasswordError('Password must include at least one uppercase letter');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    } else if (!hasLowercase) {
      setNewPasswordError('Password must include at least one lowercase letter');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    } else if (!hasDigit) {
      setNewPasswordError('Password must include at least one number');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    } else if (!hasSpecialChar) {
      setNewPasswordError('Password must include at least one special character');
      Toaster('Transaction Invalid', 'error');
      isValid = false;
    }else {
      setNewPasswordError('');
    }
    // Check if confirm password is empty
    if (confirmPassword === '') {
      setConfirmPasswordError('Confirm password is required');

      isValid = false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    
      isValid = false;
    } else {
      setConfirmPasswordError('');
    }
    if (isValid) {  
      setModelOpen(true);
      // Toaster(
      //   'Please go back to login',
      //   'success'
      // );
    }
  }

  const StyledLink = Styled.a`
    color: black;
    text-decoration: none;
    &:hover {
      color: #none;
    }`;

  return (
    <>
      <section className="ResetPassword">
        <div className="reset-password-container">
          <div className="login-logo">
            <Stack mb={4}>
              <img
                src={DIYLogo}
                alt="diylogo"
                style={{ alignSelf: 'center', width: '210px' }}
              />
            </Stack>
          </div>
          <div className="reset-password-content">
            <div className="reset-logo"></div>
            <div className="reset-form">
              <h2 className="forgot-title">
                <StyledLink href={`/auth`}><LeftCircleOutlined /></StyledLink> Reset Password
              </h2>
              <TextBoxPassword
                placeholder="New Password"
                prefix={<LockFilled />}
                onChange={(e) => setNewPassword(e.target.value)}
                errorMessage={newPasswordError}
              />
              <br />
              <TextBoxPassword 
                placeholder="Confirm Password"
                prefix={<LockFilled />}
                onChange={(e) => setConfirmPassword(e.target.value)}
                errorMessage={confirmPasswordError}
              />
              <AppModalNotifications 
                title = "Password Reset Successful"
                contents = "You will be redirected to the login page."
                isModalOpen = {modalOpen}
                handleOk = {() => setModelOpen(false)}
                onCancel = {() => navigate(`/auth`, { replace: true })}
              />
              <br />
              <Buttons onClick={handlePasswordValidation} title="Submit" 
              disabled={(newPassword && confirmPassword)? false : true}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
