
import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { APIurl } from '../../utils/apiUrl';

// Define the shape of user
    interface Store { 
    id: number;
    name?: string;
    address?: string;
    code?: string;
    city?: string;
    longitude?: string;
    latitude?: string;
    createdAt?: string;
    [key: string]: any; // Add other user properties here
}

// Define the shape of the initial state
interface StoreState {
    stores: Store[];
    formStore: Store[],
    storestoDelete: React.Key[];
    searchText: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
    selectedRow: number;
}

const initialState: StoreState = {
    stores: [],
    formStore: [],
    storestoDelete: [],
    searchText: '',
    status: 'idle',
    transactionStatus: '',
    error: '',
    selectedRow: 0,
}

// Create the async thunk
export const fetchStores = createAsyncThunk('store/fetchStores', async () => {
    const response = await axios.get(`${APIurl}/api/store`);
    // const response = await axios.get(`http://localhost:3001/api/stores`);
    return response.data;
  });

export const fetchStoreById = createAsyncThunk(
    'store/fetchStoreById',
    async (id: number) => {
        const response = await axios.get(`${APIurl}/api/store/${id}`);
        // const response = await axios.get(`http://localhost:3001/api/stores/${id}`);
        return response.data;
    }
);

export const updateStoreById = createAsyncThunk(
    'store/updateStoreById',
    async (store: Store) => {
        console.log(store)
        const response = await axios.put(`${APIurl}/api/store/${store.id}`, store);
        // const response = await axios.put(`http://localhost:3001/api/stores/${store.id}`, store);
        return response.data; // Assuming the API returns the updated stores object
    }
);

export const deleteMany = createAsyncThunk(
    'store/deleteMany',
    async (store: React.Key[]) => {
        const response = await axios.post(`${APIurl}/api/store/delete-many`, store);
        // const response = await axios.post(`http://localhost:3001/api/stores/delete-many`, store);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const createStore = createAsyncThunk(
    'store/createStore',
    async (store: Store) => {
        const response = await axios.post(`${APIurl}/api/store`, store);
        // const response = await axios.post(`http://localhost:3001/api/stores`, store);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const deleteStoreById = createAsyncThunk(
    'store/deleteStoreById',
    async (id: number) => {
        const response = await axios.delete(`${APIurl}/api/store/${id}`);
        // const response = await axios.delete(`http://localhost:3001/api/stores/${id}`);
        return response.data; // Return the ID of the deleted banner
    }
);

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload;
        },
        setStoreListsToDelete(state, action: PayloadAction<React.Key[]>) {
            state.storestoDelete = action.payload;
        },
        setDeleteStoreLists(state, action: PayloadAction<React.Key[]>) {
            const idsToDelete = action.payload;
            state.stores = state.stores.filter(store => !idsToDelete.includes(store.id));
        },
        setStoreListFromMap(state, action) {
            state.formStore = action.payload
        },
        setSelectedRow(state, action: PayloadAction<number>) {
            state.selectedRow = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStores.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStores.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stores = action.payload;
            })
            .addCase(fetchStores.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch stores';
            })
            .addCase(createStore.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(createStore.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.stores.push(action.payload);
            })
            .addCase(createStore.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to create stores, please contact your administrator';
            })
            .addCase(fetchStoreById.fulfilled, (state, action) => {
                state.formStore = action.payload;
            })
            .addCase(updateStoreById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(updateStoreById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                 state.formStore = action.payload;
                 state.error = '';
            })
            .addCase(updateStoreById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update stores, please contact your administrator';
            })
            .addCase(deleteMany.pending, (state) => {
            state.transactionStatus = 'loading';
            })
            .addCase(deleteMany.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.stores = action.payload;
                state.error = '';
            })
            .addCase(deleteMany.rejected, (state, action) => {
                state.transactionStatus = 'failed'
                state.error = action.error.message ?? 'Failed to delete stores, please contact your administrator';
            })
            .addCase(deleteStoreById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(deleteStoreById.fulfilled, (state, action) => {
                state.stores = action.payload;
                state.error = '';
                state.transactionStatus = 'succeeded';
            })
            .addCase(deleteStoreById.rejected, (state, action) => {
                state.error = action.error.message ?? 'Failed to delete stores, please contact your administrator';
                state.transactionStatus = 'failed';
            });
    },

});

export const { 
    setSearchText,
    setStoreListsToDelete,
    setDeleteStoreLists,
    setStoreListFromMap,
    setSelectedRow
} = storeSlice.actions;

export const selectStores = (state: RootState) => state.storeStore.stores;
export const selectSearchText = (state: RootState) => state.storeStore.searchText;

export const selectFilteredStores = createSelector(
    [selectStores, selectSearchText],
    (stores: Store[], searchText: string) => {
        if (!searchText) {
            return stores; // Return all stores if no searchText provided
        }
        const normalizedSearchText = searchText.toLowerCase();
        
        return stores.filter(store =>
            (store.name?.toLowerCase().includes(normalizedSearchText) || 
             store.address?.toLowerCase().includes(normalizedSearchText) || 
             store.code?.toLowerCase().includes(normalizedSearchText) ||
             store.city?.toLowerCase().includes(normalizedSearchText) ||
             store.longitude?.toLowerCase().includes(normalizedSearchText) ||
             store.createdAt?.toLowerCase().includes(searchText.toLowerCase()) || 
             store.latitude?.toLowerCase().includes(normalizedSearchText) 
           )
        );
    }
);

export default storeSlice.reducer;
