import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIurl } from '../../utils/apiUrl';
// Define the shape of account
interface Account {
    id: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    image?: string;
    updatedAt?: string;
    [key: string]: any; // Add other account properties here
}

// Define the shape of the initial state
interface AccountState {
    account: Account;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
}

const initialState: AccountState = {
    account: {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        image: '',
        updatedAt: '',
    },
    status: 'idle',
    transactionStatus: '',
    error: '',
}

export const fetchAccountById = createAsyncThunk(
    'account/fetchAccountById',
    async (id: number) => {
        const response = await axios.get(`${APIurl}/api/account/${id}`);
        return Array.isArray(response.data) && response.data.length ? response.data[0] : response.data;
    }
);

export const updateAccountById = createAsyncThunk(
    'account/updateAccountById',
    async (account: Account) => {
        const response = await axios.put(`${APIurl}/api/account/${account.id}`, account, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data; // Assuming the API returns the updated account object
    }
);

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountById.pending, (state) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(fetchAccountById.fulfilled, (state, action) => {
                state.account = action.payload;
            })
            .addCase(fetchAccountById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch account';
            })
            .addCase(updateAccountById.pending, (state) => {
                state.transactionStatus = 'loading';
                state.error = '';
            })
            .addCase(updateAccountById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.account = action.payload;
            })
            .addCase(updateAccountById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update account, please contact your administrator';
            });
    },

});

export default accountSlice.reducer;
