import React from 'react';
import { Input, Typography } from 'antd';
import './Custom.css';

const { Text } = Typography;
const { TextArea } = Input;

interface OwnProps {
    rows?: number;
    errormessage?: string;
    [key: string]: any; // Additional props
}

const TextBigArea: React.FC<OwnProps> = ({
    rows = 2,
    errormessage,
    ...restProps
}) => (
    <>
        <TextArea 
            rows={rows}
            status={errormessage ? 'error' : ''}
            {...restProps} 
        />
        <br></br>
        <br></br>
        {errormessage && (
            <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                {errormessage}
            </Text>
        )}
    </>
);

export default TextBigArea;
