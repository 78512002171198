import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Badge, Space, Popconfirm } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../redux/store";
import { deleteBannerById, setSelectedRow } from "../../redux/banner/reducer";
import { Toaster } from '../../components/common/Toaster/Toaster';
import { formatDate } from '../../utils/formatDate';
import {sorter, dateSorter} from '../../utils/tableSorter';
import './Custom.css';

const truncateDescription = (description: string, limit = 80) => {
    if (description.length > limit) {
        return description.substring(0, limit) + '...';
    }
    return description;
};

export const useBannerColumns = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleDeleteBanner = async (id: number) => {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, 1000);
        });
        await dispatch(deleteBannerById(id));
        await Toaster('Successfully Deleted Banner', 'success');
    };

    const handleAUpdateBanner = (id: number) => {
        navigate(`/banner/` + id, { replace: true });
    };

    return [
        {
            title: 'Image',
            dataIndex: 'image',
            render: (image: string | undefined) => (
                image ? <img src={image} alt="banner" style={{ width: '172px', height: '48px' }} /> : null
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: sorter('title')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: sorter('description'),
            render: (description: string) => (
                description ? truncateDescription(description) : ''
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            sorter: dateSorter('startDate'),
            render: (startDate: any) => (
                <p style={{width: '90px'}}>
                    {formatDate(startDate)}
                </p>
            )
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            sorter: dateSorter('endDate'),
            render: (endDate: any, record: { noExpiration: boolean }) => (
                <p style={{width: '90px'}}>
                    {!record.noExpiration  ?  formatDate(endDate) : "--/--/---"}
           
                </p>
                   
            )

        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                { text: 'Active', value: 1 },
                { text: 'Inactive', value: 0 },
            ],
            onFilter: (value: any, record: { status: string }) => record.status === value,
            render: (status: number) => (
                <Space>
                    <Badge
                        className={
                            status === 1 ? "blue-badge-active"
                            : "blue-badge-inactive"
                        }
                        style={{ backgroundColor: '#52c41a' }}
                    >
                        {status === 1 ? "ACTIVE" : "INACTIVE"}
                    </Badge>
                </Space>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_: any, record: { key: number }) => (
                <Space>
                    <FormOutlined id="editBtn" onClick={() => handleAUpdateBanner(record.key)} />
                    <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete this item?"
                        onConfirm={() => handleDeleteBanner(record.key)}
                        okText="Delete"
                        onVisibleChange={(visible) => {
                            if (visible) {
                                dispatch(setSelectedRow(record.key));
                            } else {
                                dispatch(setSelectedRow(0));
                            }
                        }}
                        >
                        <DeleteOutlined id="deleteBtn" />
                    </Popconfirm>
                </Space>
            )
        },
    ];
};