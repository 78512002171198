import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {  Space, Popconfirm } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../redux/store";
import { deleteCustomerById, setSelectedRow} from "../../redux/customer/reducer";
import './Custom.css';
import {sorter, dateSorter} from '../../utils/tableSorter';

const truncateName = (name: string, limit = 80) => {
    if (name.length > limit) {
        return name.substring(0, limit) + '...';
    }
    return name;
};

export const useCustomerColumns = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    
    const handleUpdateCustomer = (id: number) => {
        navigate(`/customer/` + id, { replace: true });
    };

    const handleDeleteCustomer = async (id: number) => {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, 2000);
        });
        await dispatch(deleteCustomerById(id));
    };

    return [
        {
            title: 'Name',
            dataIndex: 'firstname',
            sorter: sorter('firstname'),
            render: (firstname: string, record: any) => {
                const name = firstname + ' ' + record.lastname;
                return name ? truncateName(name) : '';
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: sorter('email'),
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile_no',
            sorter: sorter('mobile_no'),
        },
        {
            title: 'Landline No',
            dataIndex: 'landline_no',
            sorter: sorter('landline_no'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_: any, record: { key: number }) => (
                <Space>
                    <FormOutlined onClick={() => handleUpdateCustomer(record.key)} />
                    <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete this item?"
                        onConfirm={() => handleDeleteCustomer(record.key)}
                        okText="Delete"
                        onVisibleChange={(visible) => {
                            if (visible) {
                                dispatch(setSelectedRow(record.key));
                            } else {
                                dispatch(setSelectedRow(0));
                            }
                        }}
                        cancelText="Cancel"
                    >
                        <DeleteOutlined id="deleteBtn" />
                    </Popconfirm>
                </Space>
            )
        },
        
    ];
};