import * as yup from 'yup';

export const storeSchema = yup.object({
     name: yup.string().required('Name is required')
     .min(2, 'Name must be at least 2 characters')
     .max(50, 'Name must be at most 50 characters'),

     address: yup.string().required('Address is required')

    .min(2, 'Address must be at least 2 characters')
    .max(50, 'Address must be at most 50 characters'),

    code: yup.string().required('Code is required')
    .min(2, 'Code must be at least 2 characters')
    .max(50, 'Code must be at most 50 characters'),

    city: yup.string().required('City is required'),

    longitude: yup.string().required('Longitude is required'),
    
    latitude: yup.string().required('Latitude is required'),
});