import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  fetchLibrary,
  updateLibraryById,
} from "../../redux/library/reducer";
import { SetStateAction, useEffect, useRef, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { librarySchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row, Typography } from "antd";
import TextBigArea from "../../components/common/GlobalForms/TextBigArea";
import Buttons from "../../components/Buttons/Buttons";
import { UploadOutlined, GlobalOutlined, InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
import { Toaster } from "../../components/common/Toaster/Toaster";
import './Custom.css';
import moment from "moment";

const { Text } = Typography;

const LibraryForm = () => {
  const dispatch = useDispatch<AppDispatch>();

  //state data
  const [content, setContent] = useState("");
  const [image, setImage] = useState<any | null>(null);
  const [webLink, setWebLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  //error State
  const [errors, setErrors] = useState({
    contentError: "",
    imageError: "",
    webLinkError: "",
    instagramLinkError: "",
    facebookLinkError: "",
  });

  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const defaultLibrary = useSelector(
    (state: RootState) => state.storeLibrary.library
  );
  const transactionStatus = useSelector(
    (state: RootState) => state.storeLibrary.transactionStatus
  );
  const stateError = useSelector((state: RootState) => state.storeLibrary.error);

  const libraryData = {
    content,
    image,
    webLink,
    instagramLink,
    facebookLink,
    updatedAt,
  };

  useEffect(() => {
    dispatch(fetchLibrary());
    clearErrors();
  }, []);

  useEffect(() => {
    setValues();
  }, [defaultLibrary]);

  useEffect(() => {
    setIsDirty(isFormDirty(libraryData, defaultLibrary[0]));
  }, [libraryData]);

  useEffect(() => {
    if (stateError) {
      Toaster(stateError, "error");
    }
  }, [stateError]);

  const setValues = () => {
    if (defaultLibrary.length > 0) {
      const selectedLibrary = defaultLibrary[0];

      if (selectedLibrary) {
        setContent(selectedLibrary.content || "");
        setImage(selectedLibrary.image || "");
        setWebLink(selectedLibrary.webLink || "");
        setInstagramLink(selectedLibrary.instagramLink || "");
        setFacebookLink(selectedLibrary.facebookLink || "");
        setBackgroundImage(`url('${selectedLibrary.image}')`);
        setUpdatedAt(selectedLibrary.updatedAt || "");
      }
    }
  }

  const clearErrors = () => {
    setErrors({
      contentError: "",
      imageError: "",
      webLinkError: "",
      instagramLinkError: "",
      facebookLinkError: "",
    });
  };

  const handleUpdateLibraryById = async () => {
    try {
      const schema = librarySchema;
      await schema.validate(libraryData, { abortEarly: false });

      if (defaultLibrary[0].id == 0) {
        Toaster("Unable to save changes. Please try again later.", "error");
        return;
      }
  
      const data = {
        ...libraryData,
        id: defaultLibrary[0].id,
      };

      const result = await dispatch(updateLibraryById(data));

      if (updateLibraryById.fulfilled.match(result)) {
        await dispatch(fetchLibrary());
        Toaster("Successfully Updated Library", "success");
        clearErrors();
      }
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
      } else {
        console.log(err);
      }
    }
  };

  const handleSchemaError = (err: any) => {
    if (err.inner) {
      Toaster("Transaction invalid, please review your inputs", "error");
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        err.inner.forEach((error: { path: string; message: string }) => {
          const errorField = (error.path + "Error") as keyof typeof prevErrors;
          if (errorField in newErrors) {
            newErrors[errorField] = error.message;
          }
        });
        return newErrors;
      });
    } else {
      console.error(err);
    }
  };

  const handleUploadButton = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setImage(event.target.files?.item(0));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uploadedImageUrl = reader.result as string;
        setBackgroundImage(`url(${uploadedImageUrl})`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setValues();
    clearErrors();
  };

  const handleErrorChange = (
    errorSetter: string,
    value: any,
    title: string
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorSetter]: value ? "" : `${title} is required`,
    }));
  };

  const isFormDirty = (libraryData: any, defaultLibrary: any) => {
    if (defaultLibrary === undefined) return true;

    const fieldsToCompare = Object.keys(libraryData);
    for (let field of fieldsToCompare) {
      if (libraryData[field] !== defaultLibrary[field]) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ContentHeader
        title="Library"
        btnTitle="Save"
        EventClick={handleUpdateLibraryById}
        disabled={!isDirty}
        Cancel={handleCancel}
        disableCancel={!isDirty}
        Loading={transactionStatus === "loading" ? true : false}
      />
      <p style={{marginTop: "0px"}}>Changes will be reflected on the app.</p>

      <Row gutter={16} className="library-form-row">
        <Col span={8}>
          <Row gutter={16}>
            <Col span={24}>
              <h3>Quick Links</h3>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                <GlobalOutlined /> Web
              </label>

              <TextBox
                style={{ width: "100%" }}
                value={webLink}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setWebLink(e.target.value);
                  handleErrorChange("webLinkError", e.target.value, "Web link");
                }}
                errorMessage={errors.webLinkError}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                <InstagramOutlined /> Instagram
              </label>

              <TextBox
                style={{ width: "100%" }}
                value={instagramLink}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setInstagramLink(e.target.value);
                  handleErrorChange("instagramLinkError", e.target.value, "Instagram link");
                }}
                errorMessage={errors.instagramLinkError}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                <FacebookOutlined /> Facebook
              </label>

              <TextBox
                style={{ width: "100%" }}
                value={facebookLink}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setFacebookLink(e.target.value);
                  handleErrorChange("facebookLinkError", e.target.value, "Facebook link");
                }}
                errorMessage={errors.facebookLinkError}
              />
            </Col>
          </Row>
        </Col>

        <Col span={15} offset={1}>
          <Row gutter={16}>
            <Col span={24}>
              <h1>About Us</h1>
              {updatedAt && (
                <p>Last edited on <strong>{moment(updatedAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</strong> at <strong>{moment(updatedAt, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}</strong></p>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                Content
              </label>
              <TextBigArea
                rows={2}
                value={content}
                placeholder={"..."}
                style={{ width: "100%" }}
                onChange={(e: {
                  target: { value: SetStateAction<string> };
                }) => {
                  setContent(e.target.value);
                  handleErrorChange(
                    "contentError",
                    e.target.value,
                    "Content"
                  );
                }}
                errormessage={errors.contentError}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <label className="custom-label-select">Banner</label>
              <br />
              <div
                className="image-container"
                style={{ borderColor: errors.imageError ? "#ff4d4f" : "#ccc" }}
              >
                <div
                  className="image-preview"
                  style={{ backgroundImage: backgroundImage || "none" }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={(event) => {
                      handleFileUpload(event);
                      handleErrorChange("imageError", event, "Image");
                    }}
                  />
                  <Buttons
                    title="Upload Image"
                    type="file"
                    onClick={handleUploadButton}
                    style={{ width: "auto" }}
                    icon={<UploadOutlined />}
                  >
                    {" "}
                  </Buttons>
                </div>
              </div>
              <br></br>
              {errors.imageError && (
                <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                  {errors.imageError}
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LibraryForm;

