import * as yup from 'yup';

export const userSchema = yup.object({
    firstname: yup.string().required('Firstname is required')
    .min(2, 'Firstname must be at least 2 characters')
    .max(50, 'Firstname must be at most 50 characters'),

    lastname: yup.string().required('Lastname is required')
    .min(2, 'Lastname must be at least 2 characters')
    .max(50, 'Lastname must be at most 50 characters'),

    email: yup.string().email('Invalid email Please correct the format like "example@mail.com"').required('Email is required')
    .max(100, 'Email must be at most 100 characters'),
    
    role: yup.string().required('Role is required'),
    status: yup.number().required('Status is required'),
});