
 const extractNumbers = (text: string) => text.match(/\d+/g)?.map(Number) || [];

 export const sorter = (key: string) => (a: any, b: any) => {
    const numA = extractNumbers(a[key]);
    const numB = extractNumbers(b[key]);

    if (numA.length === 0 || numB.length === 0) {
        return a[key].localeCompare(b[key]);
    }

    for (let i = 0; i < Math.min(numA.length, numB.length); i++) {
        if (numA[i] !== numB[i]) {
            return numA[i] - numB[i];
        }
    }

    return a[key].localeCompare(b[key]);
};

export const dateSorter = (key: string) => (a: any, b: any) => {
    if (!new Date(a[key]).getTime()) return -1;
    if (!new Date(b[key]).getTime()) return 1;
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
};