import { Modal } from 'antd';
import React from 'react';
import Buttons from '../Buttons/Buttons';

const AppModalNotifications = (OwnProps: any) => {
    
   return(
    <>
    <div className="custom-modal-body">
      <Modal 
        open={OwnProps.isModalOpen} 
        onOk={OwnProps.handleOk} 
        onCancel={OwnProps.onCancel}
        cancelButtonProps={{ style : {display: 'none'} } }
        okButtonProps={{ style : {display: 'none'} } }
        width={360} 
        >
          <div style={{textAlign:"center"}}>
            <h2>{OwnProps.title} </h2>
            <p>{OwnProps.contents}</p>
            <Buttons 
              title="OK"
              onClick={OwnProps.onCancel}
            />
          </div>
      </Modal>
      </div>
    </>
   );}


export default AppModalNotifications;

