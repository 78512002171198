import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Badge, Space, Popconfirm } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../redux/store";
import { deleteUserById, setSelectedRow } from "../../redux/user/reducer";
import './Custom.css';
import { Role } from "../../components/constant";
import {sorter} from '../../utils/tableSorter';

const truncateName = (name: string, limit = 80) => {
    if (name.length > limit) {
        return name.substring(0, limit) + '...';
    }
    return name;
};

const userRoles = Role.map(data => ({
    value: data.id,
    label: data.description
}));

export const useUserColumns = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
 
    const handleUpdateUser = (id: number) => {
        navigate(`/user/` + id, { replace: true });
    };

    const handleDeleteUser = async (id: number) => {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, 2000);
        });
        await dispatch(deleteUserById(id));
    };

    return [
        {
            title: 'Name',
            dataIndex: 'firstname',
            sorter: sorter('firstname'),
            render: (firstname: string, record: any) => {
                const name = firstname + ' ' + record.lastname;
                return name ? truncateName(name) : '';
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: sorter('email'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                { text: 'Active', value: 1 },
                { text: 'Inactive', value: 0 },
            ],
            onFilter: (value: any, record: { status: number }) => record.status === value,
            render: (status: number) => (
                <Space>
                    <Badge
                        className={status === 1 ? "blue-badge-active" : "blue-badge-inactive"}
                        style={{ backgroundColor: '#52c41a' }}
                    >
                        {status === 1 ? "ACTIVE" : "INACTIVE"}
                    </Badge>
                </Space>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_: any, record: { key: number }) => (
                <Space>
                    <FormOutlined onClick={() => handleUpdateUser(record.key)} />
                    <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete this item?"
                        onConfirm={() => handleDeleteUser(record.key)}
                        okText="Delete"
                        onVisibleChange={(visible) => {
                            if (visible) {
                                dispatch(setSelectedRow(record.key));
                            } else {
                                dispatch(setSelectedRow(0));
                            }
                        }}

                        cancelText="Cancel"
                    >
                   <DeleteOutlined id="deleteBtn" />
                    </Popconfirm>
                </Space>
            )
        },
    ];
};
