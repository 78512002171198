import moment from "moment";

export const formatDate = (date: any) => {
  const newDate = moment(date);

  // Ensure the month is 2 digits
  const month = String(newDate.month() + 1).padStart(2, '0');
  // Ensure the day is 2 digits
  const day = String(newDate.date()).padStart(2, '0');
  // Get the full year
  const year = newDate.year();

  // Combine them in the format MM/DD/YYYY
  return `${month}/${day}/${year}`;
};
