import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  fetchAccountById,
  updateAccountById,
} from "../../redux/account/reducer";
import { SetStateAction, useEffect, useRef, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { accountSchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row, Typography, Popconfirm } from "antd";
import Buttons from "../../components/Buttons/Buttons";
import { EditFilled } from "@ant-design/icons";
import { Toaster } from "../../components/common/Toaster/Toaster";
import './Custom.css';
import defaultAvatar from "../../assets/images/avatar_default.png";
import moment from "moment";


const { Title } = Typography;

const AccountForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accesstoken = localStorage.getItem('accesstoken');
  const userId = accesstoken ? parseInt(accesstoken, 10) : 0;

  //state data
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState<any | null>(null);
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  //error State
  const [errors, setErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
  });

  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const defaultAccount = useSelector(
    (state: RootState) => state.storeAccount.account
  );

  const transactionStatus = useSelector(
    (state: RootState) => state.storeAccount.transactionStatus
  );

  const stateError = useSelector((state: RootState) => state.storeAccount.error);

  const accountData = {
    firstname,
    lastname,
    email,
    image,
    updatedAt,
  };

  useEffect(() => {
    dispatch(fetchAccountById(userId));
    clearErrors();
  }, []);

  useEffect(() => {
    setValues();
  }, [defaultAccount]);

  useEffect(() => {
    setIsDirty(isFormDirty(accountData, defaultAccount));
  }, [accountData]);

  useEffect(() => {
    if (stateError) {
      Toaster(stateError, "error");
    }
  }, [stateError]);

  const setValues = () => {
    if (defaultAccount.id > 0) {
      setFirstname(defaultAccount.firstname || "");
      setLastname(defaultAccount.lastname || "");
      setEmail(defaultAccount.email || "");
      setImage(defaultAccount.image || "");
      setUpdatedAt(defaultAccount.updatedAt || "");

      const img = new Image();
      img.src = defaultAccount.image || "";
      img.onload = () => setBackgroundImage(`url('${defaultAccount.image}')`);
      img.onerror = () => setBackgroundImage(`url('${defaultAvatar}')`);
    }
  }

  const clearErrors = () => {
    setErrors({
      firstnameError: "",
      lastnameError: "",
      emailError: "",
    });
  };

  const handleUpdateAccountById = async () => {
    try {
      const schema = accountSchema;
      await schema.validate(accountData, { abortEarly: false });

      if (defaultAccount.id == 0) {
        Toaster("Unable to save changes. Please try again later.", "error");
        return;
      }

      const data = {
        ...accountData,
        id: defaultAccount.id,
      };

      const result = await dispatch(updateAccountById(data));

      if (updateAccountById.fulfilled.match(result)) {
        await dispatch(fetchAccountById(userId));
        Toaster("Successfully Updated Account", "success");
        clearErrors();
      }
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
      } else {
        console.log(err);
      }
    }
  };

  const handleSchemaError = (err: any) => {
    if (err.inner) {
      Toaster("Transaction invalid, please review your inputs", "error");
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        err.inner.forEach((error: { path: string; message: string }) => {
          const errorField = (error.path + "Error") as keyof typeof prevErrors;
          if (errorField in newErrors) {
            newErrors[errorField] = error.message;
          }
        });
        return newErrors;
      });
    } else {
      console.error(err);
    }
  };

  const handleUploadButton = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      setImage(event.target.files?.item(0));

      const reader = new FileReader();
      reader.onloadend = () => {
        const uploadedImageUrl = reader.result as string;
        setBackgroundImage(`url(${uploadedImageUrl})`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setValues();
    clearErrors();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleErrorChange = (
    errorSetter: string,
    value: any,
    title: string
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorSetter]: value ? "" : `${title} is required`,
    }));
  };

  const isFormDirty = (accountData: any, defaultAccount: any) => {
    if (!defaultAccount.id) return false;

    const fieldsToCompare = Object.keys(accountData).filter(key => !['id', 'updatedAt', 'role', 'status'].includes(key));

    if (!('image' in defaultAccount) && !accountData.image) {
      const index = fieldsToCompare.indexOf("image");
      if (index !== -1) {
        fieldsToCompare.splice(index, 1);
      }
    }

    for (let field of fieldsToCompare) {
      if (accountData[field] !== defaultAccount[field]) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ContentHeader
        title="Account Settings"
        btnTitle="Save"
        EventClick={() => { }}
        disabled={!isDirty}
        Cancel={() => { }}
        disableCancel={!isDirty}
        savePopconfirm={{
          onConfirm: handleUpdateAccountById
        }}
        cancelPopconfirm={{
          onConfirm: handleCancel
        }}
      />
      <p style={{ marginTop: "0px" }}>Changes will be reflected on the app.
        {updatedAt && (
          <span> Last edited on <strong>{moment(updatedAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</strong> at <strong>{moment(updatedAt, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}</strong></span>
        )}
      </p>

      <Row gutter={16} className="account-form-row">
        <Col span={8}>
          <Row gutter={16}>
            <Col span={24}>
              <h3>Profile Image</h3>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <label className="custom-label-select"
                style={{ textAlign: "center" }}>Edit Profile Image</label>
              <br />
              <div
                className="image-container"
              >
                <div
                  className="image-preview"
                  style={{ backgroundImage: backgroundImage || `url(${defaultAvatar})` }}
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={(event) => {
                      handleFileUpload(event);
                    }}
                  />
                  <Buttons
                    type="file"
                    onClick={handleUploadButton}
                    icon={<EditFilled />}
                  >
                    {" "}
                  </Buttons>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={15} offset={1}>
          <Row gutter={16}>
            <Col span={24}>
              <h1>Account Details</h1>
            </Col>
          </Row>
          <br />
          <br />
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}>First Name</label>
              <TextBox
                style={{ width: "100%" }}
                value={firstname}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setFirstname(e.target.value);
                  handleErrorChange("firstnameError", e.target.value, "First Name");
                }}
                errorMessage={errors.firstnameError}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}>Last Name</label>
              <TextBox
                style={{ width: "100%" }}
                value={lastname}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setLastname(e.target.value);
                  handleErrorChange("lastnameError", e.target.value, "Last Name");
                }}
                errorMessage={errors.lastnameError}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: "20px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}>Email</label>
              <TextBox
                style={{ width: "100%" }}
                value={email}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleErrorChange("emailError", e.target.value, "Email");
                }}
                errorMessage={errors.emailError}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AccountForm;