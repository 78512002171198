import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import Buttons from "../../components/Buttons/Buttons";
import TextBox from "../../components/common/LoginForms/TextBox";
import './Custom.css';
import { SearchOutlined, PlusCircleFilled, DeleteFilled } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import DeleteButtons from "../../components/Buttons/DeleteButtons";
import MainTable from "../../components/Tables/Table";
import { useBannerColumns } from "./columns";
import { setSearchText, selectFilteredBanners, setBannerListsToDelete, fetchBanners, deleteMany } from "../../redux/banner/reducer";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useEffect } from "react";
import { Popconfirm } from "antd";

const Banner = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const columns = useBannerColumns();
    const banners = useSelector((state: RootState) => state.storeBanner.banners) || [];
    const toDeleteBannerLists = useSelector((state: RootState) => state.storeBanner.bannerstoDelete) || [];
    const filteredBanners = useSelector(selectFilteredBanners) || [];
    const searchText = useSelector((state: RootState) => state.storeBanner.searchText);
    const stateError = useSelector((state: RootState) => state.storeBanner.error);
    const transactionStatus = useSelector((state: RootState) => state.storeBanner.transactionStatus);
    const selectedRow = useSelector(
        (state: RootState) => state.storeBanner.selectedRow
      );
    useEffect(() => {
        dispatch(setBannerListsToDelete([]));
    }, []);

    useEffect(() => {
        dispatch(fetchBanners());
    }, [dispatch]);

    const data = Array.isArray(searchText ? filteredBanners : banners) 
        ? (searchText ? filteredBanners : banners).map(item => ({
            ...item,
            key: item.id,
        }))
        : [];

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchText(event.target.value));
    };

    const handleDeleteBannerLists = async () => {
        await dispatch(deleteMany(toDeleteBannerLists));
        await dispatch(setBannerListsToDelete([]));
        await Toaster('Successfully deleted banner/s', 'success');

    };

    const handleAddBanner = () => {
        navigate(`/banner/add`);
    };
    
    
  const rowClassName = (record:any) => {
    return record.key === selectedRow ? 'selected-row' : '';
  };

    return (
        <div>
            <h1>Banner Management</h1>
            <div className="banner-row">
                <div className="banner-row" style={{ marginLeft: '-20px' }}> 
                    <Buttons title="Add Banner" icon={<PlusCircleFilled />} onClick={handleAddBanner} />
                    <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete selected item/s?"
                        onConfirm={() => handleDeleteBannerLists()}
                        okText="Delete"
                    >
                        <DeleteButtons title="Delete Banner" icon={<DeleteFilled />}  disabled={toDeleteBannerLists.length < 1} loading={transactionStatus === 'loading'} />
                    </Popconfirm>
                   
                </div>
                <div style={{ marginLeft: 'auto', width: '400px' }}> 
                    <TextBox 
                        placeholder="Search" 
                        prefix={<SearchOutlined />}
                        onChange={handleSearchTextChange}
                    />
                </div>
            </div>
            <div className="table-row">
                <MainTable 
                    columns={columns} 
                    data={data}    
                    rowClassName={rowClassName}                    
                />
            </div>
        </div>
    );
}

export default Banner;
