import React from 'react'

const Category = () => {
  return (
    <>
    <div>
       <h1>Category Management</h1>
       <p>This page is under development...</p>
    </div>
    </>
  )
}

export default Category;