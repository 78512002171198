import * as yup from 'yup';

export const accountSchema = yup.object({
    firstname: yup.string().trim().required('Firstname is required')
        .min(2, 'Firstname must be at least 2 characters')
        .max(50, 'Firstname must be at most 50 characters'),

    lastname: yup.string().trim().required('Lastname is required')
        .min(2, 'Lastname must be at least 2 characters')
        .max(50, 'Lastname must be at most 50 characters'),

    email: yup.string().trim().email('Invalid email Please correct the format like "example@mail.com"').required('Email is required')
        .max(100, 'Email must be at most 100 characters'),
});