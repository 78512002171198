import React from 'react';
import AuthProvider from './components/common/AuthProvider/AuthProvider';
import AuthPage from './modules/Auth';
import LoginPage from './modules/Auth/LoginPage';
import ForgotPassword from './modules/Auth/ForgotPassword';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Banner from './modules/Banner';
import RootLayout from './components/common/RootLayout/RootLayout';
import Customer from './modules/Customer';
import BannerForm from './modules/Banner/BannerForm';
import User from './modules/User';
import UserForm from './modules/User/UserForm';
import CustomerForm from './modules/Customer/CustomerForm';
import LibraryForm from './modules/Library';
import Category from './modules/Category';
import CategoryForm from './modules/Category/CategoryForm';
import Account from './modules/Account';
// import AccountForm from './modules/Account/AccountForm';
import Store from './modules/Store';
import StoreForm from './modules/Store/StoreForm';
import ResetPassword from './modules/ResetPassword/ResetPassword';

const router = createBrowserRouter([
  {
    path: '/auth/*',
    element: <AuthPage />,
    children: [
      {
        path: '',
        element: <LoginPage/>,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      // {
      //   path: 'reset-password/:token',
      //   element: <SetPasswordPage />,
      // },
    ],
  },
  {
    element: <RootLayout />,
    path: '',
    children: [
      {
        path: '/banner',
        element: <Banner />,
      },
      {
        path: '/banner/add',
        element: <BannerForm />,
      },
      {
        path: '/banner/:id',
        element: <BannerForm />,
      },
      {
        path: '/library',
        element: <LibraryForm />,
      },
      {
        path: '/user',
        element: <User />,
      },
      {
        path: '/user/add',
        element: <UserForm />,
      },
      {
        path: '/user/:id',
        element: <UserForm/>,
      },
      {
        path: '/customer',
        element: <Customer />,
      },
      {
        path: '/customer/:id',
        element: <CustomerForm/>,
      },
      {
        path: '/customer/add',
        element: <CustomerForm/>,
      },
      {
        path: '/category',
        element: <Category />,
      },
      {
        path: '/account',
        element: <Account/>,
      },
      {
        path: '/store',
        element: <Store />,
      },
      {
        path: '/store/add',
        element: <StoreForm/>,
      },
      {
        path: '/store/:id',
        element: <StoreForm/>,
      },

      // {
      //   path: '/product',
      //   element: <Product />,
      // },
      // {
      //   path: '/product/add',
      //   element: <ProductForm />,
      // },
    ]
    }
]);

function App() {
  return (
    <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
  );
}

export default App;
