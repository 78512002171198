import React from 'react';
import { Button } from 'antd';
import './Custom.css';

const Buttons = (Props: any) => {
    return (
        <>
            <div className={
            Props.disabled == true ? 
            "custom-button-no-hover"
            : "custom-button"
        }>
                <Button block {...Props}
                
                > {Props.title} </Button>
            </div>
        </>
    )
}
export default Buttons;
