import { FilterUsersProps } from "../types";
import { usersData } from "../components/constant";


export async function fetchUsers(filters: FilterUsersProps){
    const { id, firstName, lastName, email } = filters;

    const filterUsers = usersData.filter(user => {

        const userFirstName = user.firstName.toLowerCase();
        const userLastName = user.lastName.toLowerCase();
        const userEmail = user.email.toLowerCase();
        const userID = user.id;
        return (
            (!firstName || userFirstName.includes(firstName.toLowerCase())) &&
            (!lastName || userLastName.includes(lastName.toLowerCase())) &&
            (!id || userID) &&
            (!email || userEmail.includes(email.toLowerCase()))
        )

    })
    return filterUsers;
}

export async function logginIn(email: string, password: string) {
   const users = usersData.filter(user => user.email === email && user.password === password);
   if (users.length > 0) return Array(users[0]);
   else return []
            
}   