import { configureStore } from "@reduxjs/toolkit";
import rooteReducer from "./rootReducer";

const store = configureStore({
    reducer: rooteReducer
})


export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;