import React, { useState } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setBannerListsToDelete } from '../../redux/banner/reducer';
import { setUserListsToDelete } from '../../redux/user/reducer';
import { setCustomerListsToDelete } from '../../redux/customer/reducer';
// import { setvariationListsToDelete } from '../../redux/variation/reducer';
import { setStoreListsToDelete } from '../../redux/store/reducer';

type TableRowSelection<T> = TableProps<T>['rowSelection'];

interface MyComponentProps {
    columns: any;
    data: any;
    rowClassName?: any;     
  }

const MainTable = (OwnProps: MyComponentProps) => {
const dispatch = useDispatch<AppDispatch>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    dispatch(setBannerListsToDelete(newSelectedRowKeys))
    dispatch(setUserListsToDelete(newSelectedRowKeys))
    dispatch(setCustomerListsToDelete(newSelectedRowKeys))
    dispatch(setStoreListsToDelete(newSelectedRowKeys))
    // dispatch(setvariationListsToDelete(newSelectedRowKeys))
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE, 
    ],
  };


  return( 
    <>
         <Table 
            rowSelection={rowSelection} 
            columns={OwnProps.columns} 
            dataSource={OwnProps.data}
            rowClassName={OwnProps.rowClassName}
         />
    </>

  )
};

export default MainTable;