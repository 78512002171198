import React from 'react';
import { Radio, Typography } from 'antd';
import './Custom.css';
const { Text } = Typography;


interface Option {
  value: string | number;
  label: string;
}

interface OwnProps {
  onChange: any;
  value: any;
  options: Option[];
  errorMessage?: string;
}

const AppRadio: React.FC<OwnProps> = ({ onChange, value, options,errorMessage }) => {
  return (
    <>
        <Radio.Group onChange={onChange} value={value}
        >
        {
            options.map(item => (
            <Radio value={item.value} key={item.value}>{item.label}</Radio>
            ))
        }
        
        </Radio.Group>
        <br /><br />
        {errorMessage && (
            <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                {errorMessage}
            </Text>
        )}
    </>
  );
};

export default AppRadio;
