import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider/AuthProvider';

const AuthenticatedRoute = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const storedToken = localStorage.getItem('accesstoken');
  
  if (storedToken == null) {

    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
};

export default AuthenticatedRoute;
