import React from 'react';
import AppContent from '../../../modules/Layout';
import AuthenticatedRoute from '../AuthenticatedRoute/AuthenticatedRoute';
const RootLayout = () => {
  return (
    <AuthenticatedRoute>
      <AppContent />
    </AuthenticatedRoute>
  );
};

export default RootLayout;
