import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIurl } from '../../utils/apiUrl';

// Define the shape of the library
interface Library {
    id: number;
    content: string;
    image: string;
    webLink: string;
    instagramLink: string;
    facebookLink: string;
    updatedAt: string;
    [key: string]: any; // Add other library properties here
}

// Define the shape of the initial state
interface LibraryState {
    library: Library[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
}

const initialState: LibraryState = {
    library: [{
        id: 0,
        content: '',
        image: '',
        webLink: '',
        instagramLink: '',
        facebookLink: '',
        updatedAt: '',
    }],
    status: 'idle',
    error: '',
    transactionStatus: '',
}

// Create the async thunk
export const fetchLibrary = createAsyncThunk('library/fetchLibrary', async () => {
    const response = await axios.get(`${APIurl}/api/library`);
    return response.data;
  });

export const updateLibraryById = createAsyncThunk(
    'library/updateLibraryById',
    async (library: Library) => {
        console.log(library)
        const response = await axios.put(`${APIurl}/api/library/${library.id}`, library, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data; // Assuming the API returns the updated library object
    }
);

const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLibrary.pending, (state) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(fetchLibrary.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.library = action.payload;
            })
            .addCase(fetchLibrary.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch library';
            })
            .addCase(updateLibraryById.pending, (state) => {
                state.transactionStatus = 'loading';
                state.error = '';
            })
            .addCase(updateLibraryById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.library = action.payload;
                state.error = '';
            })
            .addCase(updateLibraryById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update library, please contact your administrator';
            });
    },
});

export default librarySlice.reducer;
