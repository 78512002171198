import React from "react";
import LoginPage from "./LoginPage";
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import backgroundImage from '../../assets/images/background.png';
import backgroundImage2 from '../../assets/images/background2.png'
import './Custom.css';

const AuthPage = () => {
    return (
        <>
        <div className="login_background_image">
            <Stack
                sx={{
                 
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: '100%',
                    backgroundRepeat: `no-repeat`,
                }}
                height='65vh'
                width='100vw'
                direction='column'
                justifyContent='center'
                alignItems='center'
                //boxShadow={3}
                spacing={2}
                >
            
                </Stack>
                <Stack
                    sx={{
                        zIndex: -1,
                        backgroundImage: `url(${backgroundImage2})`,
                        backgroundSize: '100%',
                        backgroundRepeat: `no-repeat`,
                        backgroundPositionY: 'center'
                    }}
                    height='35vh'
                    width='100vw'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    //boxShadow={3}
                >
                <Outlet />
            </Stack>
            </div>
        </>
    )
}

export default AuthPage;