import * as yup from 'yup';

export const customerSchema = yup.object({
    firstname: yup.string().required('Firstname is required')
    .min(2, 'Firstname must be at least 2 characters')
    .max(50, 'Firstname must be at most 50 characters'),

    lastname: yup.string().required('Lastname is required')
    .min(2, 'Lastname must be at least 2 characters')
    .max(50, 'Lastname must be at most 50 characters'),

    email: yup.string().email('Invalid email Please correct the format like "example@mail.com"').required('Email is required'),
    
    mobile_no: yup.string().required('Mobile No is required')
    .matches(/^\d+$/, 'Mobile No must be a valid number')
    .min(11, 'Mobile No must be at least 11 digits')
    .max(11, 'Mobile No must be at least 11 digits'),

    landline_no: yup.string().required('Landline_no is required')
    .matches(/^\d+$/, 'Landline No must be a valid number')
    .min(10, 'Mobile No must be at least 10 digits')
    .max(10, 'Mobile No must be at least 10 digits'),
});