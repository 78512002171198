// import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getLoggedInUser } from '../../../services/auth';

export interface AuthUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    // dateCreated: Date;
    // accessToken: string;
    // roleId: string;
  }

  
interface AuthProviderValue {
  authUser: AuthUser | null;
//   currentUserRole: Role | null;
  logout: () => void;
  setAuthUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  //setCurrentUserRole: React.Dispatch<React.SetStateAction<Role | null>>;

  isLoading: boolean;
}

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const storedToken = localStorage.getItem('accesstoken');
  const token = storedToken ? JSON.parse(storedToken) : null;

  const [authUser, setAuthUser] = useState<AuthUser | any>(null);
  //const [currentUserRole, setCurrentUserRole] = useState<Role | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const authUserId = storedToken ? JSON.parse(storedToken) : null;
  console.log(authUserId)
   useEffect(() => {
  //   if (!token) return;

    const getMe = async () => {
  //     // decode token
     try {
       setIsLoading(true);
        //const decoded: any = jwtDecode(token ?? '');

        const currentUser = await getLoggedInUser(authUserId);
       
        setAuthUser(currentUser);
       // const userRole = await rolesApi.getRoleById(authUserId);
       // setCurrentUserRole(userRole);
      } catch (error) {
        //setAuthUser(null);
      
          return;
        
      } finally {
        setIsLoading(false);
      }
    };

     getMe();
  }, [authUserId]);

  const logout = async () => {
    //await authApi.logout();
    setAuthUser(null);
    localStorage.clear();
  };

  const value = useMemo(() => {
    return {
      //currentUserRole,
      authUser,
      logout,
      setAuthUser,
     // setCurrentUserRole,
      isLoading,
    };
  }, [authUser, isLoading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
