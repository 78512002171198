import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import Buttons from "../../components/Buttons/Buttons";
import TextBox from "../../components/common/LoginForms/TextBox";
import './Custom.css';
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { PlusCircleFilled, DeleteFilled } from '@ant-design/icons'; 
import DeleteButtons from "../../components/Buttons/DeleteButtons";
import MainTable from "../../components/Tables/Table";
import { setSearchText, selectFilteredStores, deleteMany,  setStoreListsToDelete, fetchStores} from "../../redux/store/reducer";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useStoreColumns } from "./columns";
import { useEffect } from "react";
import { Popconfirm } from "antd";

const Store = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const columns = useStoreColumns();
  const Stores = useSelector((state: RootState) => state.storeStore.stores);
  const toDeleteStoreLists = useSelector((state: RootState) => state.storeStore.storestoDelete);
  const filteredStores = useSelector(selectFilteredStores);
  const searchText = useSelector((state: RootState) => state.storeStore.searchText);
  const transactionStatus = useSelector((state: RootState) => state.storeStore.transactionStatus);

  const selectedRow = useSelector(
    (state: RootState) => state.storeStore.selectedRow
  );
  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setSearchText(event.target.value));
  };

  useEffect(() => {
      dispatch(fetchStores());
  }, [dispatch]);

  const handleDeleteStoreLists = async () => {
      await dispatch(deleteMany(toDeleteStoreLists));
      await dispatch(setStoreListsToDelete([]));
      await Toaster('Successfully Deleted Stores', 'success');
  };
  
  const handleAddStore = () => {
      navigate(`/store/add`, { replace: true });
  };
  
  const data = searchText ? filteredStores.map(item => ({
      ...item,
      key: item.id,
  })) : 
  Stores.map(item => ({
      ...item,
      key: item.id,
  }))
  ;

      
  const rowClassName = (record:any) => {
    return record.key === selectedRow ? 'selected-row' : '';
  };


     
  return (
      <>
          <div>
              <h1>Store Management</h1>
              <div className="store-row">
               <div className="store-row" style={{marginLeft: '-20px'}}> 
                      <Buttons title="Add Store" icon={<PlusCircleFilled />} onClick={handleAddStore} />
                      <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete selected item/s?"
                        onConfirm={() => handleDeleteStoreLists()}
                        okText="Delete"
                        >
                   <DeleteButtons title="Delete Store" icon={<DeleteFilled />} disabled={toDeleteStoreLists.length < 1} loading={transactionStatus === 'loading'}
                        />
                        </Popconfirm>
                  </div>
                  <div style={{ marginLeft: 'auto', width:' 400px' }}> 
                      <TextBox 
                          placeholder="Search" 
                          prefix={<SearchOutlined />}
                          onChange={handleSearchTextChange}
                      />
                  </div>
              </div>

              <div className="table-row">
                  <MainTable 
                      columns={columns} data={data} rowClassName={rowClassName}                                        
                  />
              </div>
          </div>
      </>
  );
}

export default Store