
import moment from 'moment';
import * as yup from 'yup';

export const bannerSchema = yup.object().shape({
  description: yup.string().required('Description is required'),
  title: yup.string().required('Title is required'),
  image: yup.string().required('Image is required'),
  startDate: yup.string().required('Start Date is required'),
  endDate: yup.string().required('End Date is required'),
  status: yup.number().required('Status is required'),
}).test('date-check', 'Start date must be less than End date', function(value) {
    const { startDate, endDate } = value;
    return startDate && endDate ? moment(startDate).isSameOrBefore(endDate) : true;
});



export const bannerWithExpirySchema = yup.object({
    description: yup.string().required('Description is required'),
    title: yup.string().required('Title is required'),
    image: yup.string().required('Image is required'),
    startDate: yup.string().required('Start Date is required').nullable(),
    endDate: yup.string().optional().nullable(),
    status: yup.number().required('Status is required'),
});