import React from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input,Space,Typography } from 'antd';
import './Custom.css';
const { Text } = Typography;

interface OwnProps {
    label?: string;
    placeholder?: string;
    prefix?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
    id?: string;
}
const TextBoxPassword = (OwnProps: OwnProps) => {

    return (
        <div className="custom-input-wrapper">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input.Password
                    id={OwnProps.id}
                    placeholder={OwnProps.placeholder} 
                    prefix={OwnProps.prefix}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={OwnProps.onChange}
                    status={OwnProps.errorMessage ? 'error' : ''}
                />
                {
                    OwnProps.errorMessage ? 
                    <Text type="danger" style={{float:"left"}}>{OwnProps.errorMessage}</Text> 
                    : null
                }
            </Space>
        </div>
    )
}

export default TextBoxPassword;
