import React from 'react';
import { Input, Space, Typography } from 'antd';
import './Custom.css';
const { Text } = Typography;

interface OwnProps {
    label?: string;
    placeholder?: string;
    prefix?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
    value?: string;
    id?: any;
}
const TextBox = (OwnProps: OwnProps) => {

    return (
        <div className="custom-input-wrapper"  >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input
                    id={OwnProps.id}
                    value={OwnProps.value}
                    placeholder={OwnProps.placeholder} 
                    prefix={OwnProps.prefix}
                    onChange={OwnProps.onChange}
                    status={OwnProps.errorMessage ? 'error' : ''}
                />
                {
                    OwnProps.errorMessage ? 
                    <Text type="danger" style={{float:"left", fontSize: "12px !important"}}>{OwnProps.errorMessage}</Text> 
                    : null
                }
                </Space>
        </div>
    )
}

export default TextBox;
