import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import Buttons from "../../components/Buttons/Buttons";
import TextBox from "../../components/common/LoginForms/TextBox";
import './Custom.css';
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { PlusCircleFilled, DeleteFilled } from '@ant-design/icons'; 
import DeleteButtons from "../../components/Buttons/DeleteButtons";
import MainTable from "../../components/Tables/Table";
import { useUserColumns } from "./columns";
import { setSearchText, selectFilteredUsers, setDeleteUserLists, setUserListsToDelete, fetchUsers, deleteMany} from "../../redux/user/reducer";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useEffect } from "react";
import { Popconfirm } from "antd";


const User = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const columns = useUserColumns();
    const Users = useSelector((state: RootState) => state.storeUser.users) || [];
    const toDeleteUserLists = useSelector((state: RootState) => state.storeUser.userstoDelete) || [];
    const filteredUsers = useSelector(selectFilteredUsers) || [];
    const searchText = useSelector((state: RootState) => state.storeUser.searchText);
    const transactionStatus = useSelector((state: RootState) => state.storeUser.transactionStatus);
    const selectedRow = useSelector(
        (state: RootState) => state.storeUser.selectedRow
      );
    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchText(event.target.value));
    };

    useEffect(() => {
        dispatch(setUserListsToDelete([]));
    }, []);

    
    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);


    const handleDeleteUserLists = async () => {
        await dispatch(deleteMany(toDeleteUserLists));
        await dispatch(setUserListsToDelete([]));
        await Toaster('Successfully deleted Users', 'success');
    };
    
    const handleAddUser = () => {
        navigate(`/user/add`, { replace: true });
    };
    
    const data = searchText ? filteredUsers.map(item => ({
        ...item,
        key: item.id,
    })) : 
    Users.map(item => ({
        ...item,
        key: item.id,
    }))
    ;

  const rowClassName = (record:any) => {
    return record.key === selectedRow ? 'selected-row' : '';
  };
       
    return (
        <>
            <div>
                <h1>User Management</h1>
                <div className="user-row">
                 <div className="user-row" style={{marginLeft: '-20px'}}> 
                        <Buttons title="Add User" icon={<PlusCircleFilled />} onClick={handleAddUser} />
                        <Popconfirm
                        title="Delete Message"
                        description="Are you sure you want to delete selected items?"
                        onConfirm={() => handleDeleteUserLists()}
                        okText="Delete"
                        >
                        <DeleteButtons title="Delete User" icon={<DeleteFilled />} disabled={toDeleteUserLists.length < 1} loading={transactionStatus === 'loading'}
                        />
                        </Popconfirm>
                    </div>
                    <div style={{ marginLeft: 'auto', width:' 400px' }}> 
                        <TextBox 
                            placeholder="Search" 
                            prefix={<SearchOutlined />}
                            onChange={handleSearchTextChange}
                        />
                    </div>
                </div>

                <div className="table-row">
                    <MainTable 
                        columns={columns} data={data} rowClassName={rowClassName}                   
                    />
                </div>
            </div>
        </>
    );
}

export default User;
