import { combineReducers } from "redux";
import storeBanner from "./banner/reducer";
import storeLibrary from "./library/reducer";
import storeUser from "./user/reducer";
import storeCustomer from "./customer/reducer";
import storeStore from "./store/reducer";
import storeAccount from "./account/reducer";

const rooteReducer = combineReducers({
    storeBanner: storeBanner,
    storeLibrary: storeLibrary,
    storeUser: storeUser,
    storeCustomer: storeCustomer,
    storeStore: storeStore,
    storeAccount: storeAccount,
})

export default rooteReducer;