import { Col, Row, Popconfirm } from "antd";
import Buttons from "../../Buttons/Buttons";
import { CheckCircleFilled, LeftCircleOutlined, QuestionCircleFilled } from "@ant-design/icons";

const ContentHeader = (OwnProps: any) => {
    const saveButton = (
        <Buttons
            title={OwnProps.btnTitle}
            icon={<CheckCircleFilled width={30} />}
            onClick={OwnProps.EventClick}
            disabled={OwnProps.disabled}
            loading={OwnProps.Loading}
            style={{ marginRight: '-210px' }} // Add margin to the right button
        />
    );

    const cancelButton = (
        <Buttons
            title="Cancel"
            onClick={OwnProps.Cancel}
            style={OwnProps.disableCancel ? {} : { backgroundColor: "#292D32", color: "white" }}
            disabled={OwnProps.disableCancel}
        />
    );

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <Col
                            span={2}
                            style={{ display: OwnProps.backLink ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <LeftCircleOutlined
                                style={{ fontSize: '34px', marginRight: '5px' }}
                                onClick={OwnProps.backLink}
                            />
                        </Col>
                        <Col span={22}>
                            <h2>{OwnProps.title}</h2>
                        </Col>
                    </Row>
                </Col>
                <Col span={15}>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        <Col style={{ marginRight: '-20px' }}>
                            {OwnProps.savePopconfirm ? (
                                <Popconfirm
                                    title={OwnProps.savePopconfirm.title || ""}
                                    description={OwnProps.savePopconfirm.desciption || "Are you sure you want to save the changes?"}
                                    okText={OwnProps.savePopconfirm.okText || "Yes"}
                                    cancelText={OwnProps.savePopconfirm.cancelText || "No"}
                                    icon={<QuestionCircleFilled />}
                                    onConfirm={OwnProps.savePopconfirm.onConfirm}
                                >
                                    {saveButton}
                                </Popconfirm>
                            ) : (
                                saveButton
                            )}
                        </Col>
                        <Col>
                            {OwnProps.cancelPopconfirm ? (
                                <Popconfirm
                                    title={OwnProps.cancelPopconfirm.title || "Cancel"}
                                    description={OwnProps.cancelPopconfirm.desciption || "Are you sure you want to cancel? Your changes won’t be saved."}
                                    okText={OwnProps.cancelPopconfirm.okText || "Yes"}
                                    cancelText={OwnProps.cancelPopconfirm.cancelText || "No"}
                                    icon={<QuestionCircleFilled />}
                                    onConfirm={OwnProps.cancelPopconfirm.onConfirm}
                                >
                                    {cancelButton}
                                </Popconfirm>
                            ) : (
                                cancelButton
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ContentHeader;
