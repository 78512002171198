

import { useLayout } from "./LayoutContext"
import { Layout } from 'antd';

const {  Content } = Layout;

const Contents = ({ children }: { children: React.ReactNode }) => {
    const layout = useLayout();
    return (
        <Content
        style={{
          margin: '24px 16px',
          padding: '10px 30px 30px 30px',
          minHeight: 280,
          background: layout.colorBgContainers,
          height:"350px", 
          overflowX: 'auto'
        
        }}
      >
        {children}
      </Content>

    )
}

export default Contents