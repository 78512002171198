import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { createUser, fetchUsers, fetchUserById, updateUserById } from "../../redux/user/reducer";
import { SetStateAction, useEffect, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { userSchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row, Typography } from "antd";
import SelectDropdown from "../../components/common/GlobalForms/Select";
import AppRadio from "../../components/common/GlobalForms/AppRadio";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { Role } from "../../components/constant";

const { Text } = Typography;

const UserForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: any }>();

   //state data
   const [firstname, setFirstname] = useState("");
   const [lastname, setLastname] = useState("");
   const [email, setEmail] = useState("");
   const [role, setRole] = useState("");
   const [status, setStatus] = useState<number | null>(null);
   const [isDirty, setIsDirty] = useState(false);

  //error State
  const [errors, setErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    roleError: "",
    statusError: "",
  });

    const defaultUser = useSelector((state: RootState) => state.storeUser.users);

    const transactionStatus = useSelector(
        (state: RootState) => state.storeUser.transactionStatus
    );

    const stateError = useSelector((state: RootState) => state.storeUser.error);

    const userData = {
        firstname,
        lastname,
        email,
        role,
        status,
    };

    const userRoles = Role.map(data => ({
        value: data.id,
        label: data.description
    }));
    
    useEffect(() => {
        if (id) {
          const numericId = parseInt(id, 10);
          dispatch(fetchUsers());
          dispatch(fetchUserById(numericId));
          clearErrors();
        }
      }, [id, dispatch]);
    
      useEffect(() => {
        if (id && defaultUser.length > 0) {
          const numericId = parseInt(id, 10);
          //dispatch(fetchBannerById(numericId));
          const selectedUser = defaultUser.find(
            (user) => user.id === numericId
          );
    
          if (selectedUser) {
            setFirstname(selectedUser.firstname || "");
            setLastname(selectedUser.lastname || "");
            setEmail(selectedUser.email || "");
            setRole(selectedUser.role || "");
            setStatus(selectedUser.status);
          }
        }
      }, [defaultUser, id, dispatch]);

      useEffect(() => {
        setIsDirty(isFormDirty(userData, defaultUser[0]));
      }, [userData, defaultUser]);
    
    
      const clearErrors = () => {
        setErrors({
          firstnameError: "",
          lastnameError: "",
          emailError: "",
          roleError: "",
          statusError: "",
        });
      };
  

      const handleUserForm = async () => {
        try {
         const schema = userSchema;
          await schema.validate(userData,{ abortEarly: false });
    
          const data = {
            id: defaultUser.length + 1,
            ...userData,
          };
    
          await dispatch(createUser(data));
    
          if (stateError === "") {
            await dispatch(fetchUsers());
            navigate(`/user`, { replace: true });
            await Toaster("Successfully Added User", "success");
            clearErrors();
          } else {
            await Toaster(stateError, "error");
          }
        } catch (err: any) {
          if (err.inner) {
            handleSchemaError(err);
          } else {
            console.log(err);
          }
        }
      };

      const handleSchemaError = (err: any) => {
        if (err.inner) {
          Toaster("Transaction invalid, please review your inputs", "error");
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            err.inner.forEach((error: { path: string; message: string }) => {
              const errorField = (error.path + "Error") as keyof typeof prevErrors;
              if (errorField in newErrors) {
                newErrors[errorField] = error.message;
              }
            });
            return newErrors;
          });
        } else {
          console.error(err);
        }
      };

    
    const handleErrorChange = (
      errorSetter: string,
      value: any,
      title: string
    ) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [errorSetter]: value ? "" : `${title} is required`,
      }));
    };
  

    const handleBackToMain = () => {
        navigate(`/user`, { replace: true });
    };

    const handleUpdateUserById = async () => {
      try {
        const schema = userSchema;
        await schema.validate(userData, { abortEarly: false });
  
        const data = {
          ...userData,
          id: parseInt(id),
          test: 123,
        };
  
        await dispatch(updateUserById(data));
  
        if (stateError ==="") {
          await dispatch(fetchUsers());
          await Toaster("Successfully Updated User", "success");
          navigate(`/user`, { replace: true });
          clearErrors();
        } else await Toaster(stateError, "error");
      } catch (err: any) {
        if (err.inner) {
          handleSchemaError(err);
        } else {
          console.log(err);
        }
      }
    };
    
  const isFormDirty = (userData: any, defaultUser: any) => {
    if (defaultUser === undefined) return true;
    const fieldsToCompare = Object.keys(userData);
    for (let field of fieldsToCompare) {
      if (userData[field] !== defaultUser[field]) {
        return true;
      }
    }
    return false;
  }
  
    return (
                <>
                    <ContentHeader 
                      backLink={handleBackToMain} 
                      title={id ? "Update User" : "New User"}  
                      btnTitle="Save" 
                      EventClick={id ? handleUpdateUserById : handleUserForm}  
                      cancelPopconfirm={{
                          title: "Cancel",
                          description: "Are you sure you want to cancel? Your changes won’t be saved.",
                          okText: "Yes",
                          cancelText: "No",
                          onConfirm: handleBackToMain // Function to handle navigation
                        }}  
                      Loading={transactionStatus === "loading" ? true : false}  
                      disabled={!isDirty || !(firstname || lastname || email || role || status)}
                      >
                    </ContentHeader>
                 <h1>User Information</h1>
                 <Row gutter={16}>
                    <Col span={24} style={{paddingLeft: "0px", paddingBottom: "20px"}}>
                        <Row gutter={16}>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Firstname</label>
                                <TextBox
                                    style={{ width: '100%' }}
                                    value={firstname}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                      setFirstname(e.target.value); 
                                      handleErrorChange(
                                        "firstnameError",
                                        e.target.value,
                                        "First Name"
                                      );
                                      }}
                                    errorMessage={errors.firstnameError}
                                />
                            </Col>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Lastname</label>
                            <TextBox
                                    style={{ width: '100%' }}
                                    value={lastname}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                      setLastname(e.target.value); 
                                      handleErrorChange(
                                        "lastnameError",
                                        e.target.value,
                                        "Last Name"
                                      );
                                      }}
                                    errorMessage={errors.lastnameError}
                                />
                            </Col>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Email</label>
                            <TextBox
                                    style={{ width: '100%' }}
                                    value={email}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                      setEmail(e.target.value); 
                                      handleErrorChange(
                                        "emailError",
                                        e.target.value,
                                        "Email"
                                      );
                                      }}
                                    errorMessage={errors.emailError}
                                />
                            </Col>
                        </Row>
                    </Col>
                   
                    <Col span={24} style={{paddingLeft: "10px"}}>
                        <Row gutter={16}>
                            <Col span={12}>
                              
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={18}>
                                <Row gutter={16}>
                                <Col span={8}>
                                <label className='custom-label-select' style={{ display: 'block' }}>Role </label>
                                <SelectDropdown
                                placeholder={"..."}
                                defaultValue={""}
                                value={role}
                                options={userRoles}
                                style={{ width: "100%" }}
                                onChange={(value: SetStateAction<any>) => {
                                  setRole(value);
                                }}
                                errorMessage={errors.roleError}
                                />
                              </Col>
                            <Col span={8}>
                  <label
                    className="custom-label-select"
                    style={{ display: "block" }}
                  >
                    Status
                  </label>
                  <br></br>
                  <AppRadio
                    options={[
                      { value: 1, label: "Active" },
                      { value: 0, label: "Inactive" },
                    ]}
                    value={status}
                    onChange={(e: any) => {
                      setStatus(e.target.value);
                      handleErrorChange(
                        "statusError",
                        true,
                        "Status"
                      );
                    }}
                    errorMessage={errors.statusError}
                  />
                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    </>
    )

}

export default UserForm;
