import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLayout } from '../layout-ui/LayoutContext';
import {
  BookOutlined,
  UploadOutlined,
  UserOutlined,
  PictureOutlined,
  BankOutlined,
  SolutionOutlined,
  ShopOutlined,
  SettingOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import MRDIYlogo from '../../../assets/images/MRDIYlogo.png';
import shortLogo from '../../../assets/images/shortLogo.png';
import './Custom.css';

const { Sider } = Layout;

const SideBar = () => {
  const layout = useLayout();
  const location = useLocation();
  const { id } = useParams(); 

  const selectedKeys = [location.pathname];
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={layout.collapsed}
      style={{
        height: '100vh',
        left: 0,
        padding: '20px',
        backgroundColor: '#DCDCDC',
      }}
      width={280}
    >
      <div className="demo-logo-vertical">
        {!layout.collapsed ? (
          <a href="#"><img src={MRDIYlogo} alt="logo" /></a>
        ) : (
          <a href="#"><img src={shortLogo} alt="logo" width={'50px'} /></a>
        )}
      </div>
      <Menu
        theme="light"
        style={{
          backgroundColor: '#DCDCDC',
          borderInlineEnd: 'none',
          fontSize: '13px',
        }}
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        items={[
          {
            key: selectedKeys[0] === '/banner' ? '/banner' : (selectedKeys[0] === `/banner/${id}` ?  `/banner/${id}`: '/banner/add'),
            icon: (
              <PictureOutlined 
                style={{
                  color: selectedKeys.includes(selectedKeys[0] === '/banner' ? '/banner' : (selectedKeys[0] === `/banner/${id}` ?  `/banner/${id}`: '/banner/add')) ? '#F1C40F' : '',
                 
                }}
              />
            ),
            label: (
              <Link to="/banner">
                Banner Management
              </Link>
            ),
          },
          {
            key: selectedKeys[0] === '/user' ? '/user' : (selectedKeys[0] === `/user/${id}` ?  `/user/${id}`: '/user/add'),
            icon: (
              <UserOutlined
                style={{
                  color: selectedKeys.includes(selectedKeys[0] === '/user' ? '/user' : (selectedKeys[0] === `/user/${id}` ?  `/user/${id}`: '/user/add')) ? '#F1C40F' : '',
                }}
              />
            ),
            label: (
              <Link to="/user">
                User Management
              </Link>
            ),
          },
          // {
          //   key: selectedKeys[0] === '/customer' ? '/customer' : (selectedKeys[0] === `/customer/${id}` ?  `/customer/${id}`: '/customer/add'),
          //   icon: (
          //     <SolutionOutlined
          //       style={{
          //         color: selectedKeys.includes('/customer') ? '#F1C40F' : '',
          //       }}
          //     />
          //   ),
          //   label: (
          //     <Link to="/customer">
          //       Customer Management
          //     </Link>
          //   ),
          // },
          {
            key: '/category',
            icon: (
              <AppstoreAddOutlined
                style={{
                  color: selectedKeys.includes('/category') ? '#F1C40F' : '',
                }}
              />
            ),
            label: (
              <Link to="category">
                Category Management
              </Link>
            ),
          },
          {
            key: selectedKeys[0] === '/store' ? '/store' : (selectedKeys[0] === `/store/${id}` ?  `/store/${id}`: '/store/add'),
            icon: (
              <BankOutlined 
                style={{
                  color: selectedKeys.includes('/store') ? '#F1C40F' : '',
                }}
              />
            ),
            label: (
              <Link to="/store">
                Store Management
              </Link>
            ),
          },
          {
            key: '/library',
            icon: (
              <BookOutlined
                style={{
                  color: selectedKeys.includes('/library') ? '#F1C40F' : '',
                 
                }}
              />
            ),
            label: (
              <Link to="/library">
                Library
              </Link>
            ),
          },

          {
            key: '/account',
            icon: (
              <SettingOutlined
                style={{
                  color: selectedKeys.includes('/account') ? '#F1C40F' : '',
                }}
              />
            ),
            label: (
              <Link to="/account">
                Account Settings
              </Link>
            ),
          },

        ]}
      />
    </Sider>
  );
};
export default SideBar;

