import { useState } from "react";
import TextBox from "../../components/common/LoginForms/TextBox";
import TextBoxPassword from "../../components/common/LoginForms/TextBoxPassword";
import { MailFilled, LockFilled } from '@ant-design/icons';
import Buttons from "../../components/Buttons/Buttons";
import { logginIn } from "../../utils";
import { Navigate } from 'react-router-dom';
import './Custom.css';
import Styled from 'styled-components';
import * as yup from 'yup';
import { Toaster } from "../../components/common/Toaster/Toaster";
import { useAuth } from "../../components/common/AuthProvider/AuthProvider";
import { Stack } from '@mui/material';
import DIYLogo from '../../assets/images/diy_main_logo.png';

const loginSchema = yup.object({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required'),
});

const StyledLink = Styled.a`
    color:black;
    text-decoration:underline;
    font-size:12px; /* Add font size */
    &:hover {
        color:#F6BF06;}`;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const auth = useAuth();
   
    const Login = async () => {
        try {
            
            await loginSchema.validate({ email, password }, { abortEarly: false });
            const userData = await logginIn(email, password);
           
            if(Array.isArray(userData) && userData.length > 0){
                    Toaster(
                        'You have successfully logged in',
                        'success'
                    );
                    auth?.setAuthUser(userData[0]);
                    localStorage.setItem('accesstoken', JSON.stringify(userData[0].id));
                } else {
                    Toaster(
                        'Please Input correct email and password',
                        'error'
                    );
                }
            
            setEmailError('');
            setPasswordError('');

        } catch (err: any) {
            if (err.inner) {
                const emailErr = err.inner.find((e: { path: string; }) => e.path === 'email');
                const passwordErr = err.inner.find((e: { path: string; }) => e.path === 'password');
                setEmailError(emailErr ? emailErr.message : '');
                setPasswordError(passwordErr ? passwordErr.message : '');
            } else {
                console.log(err);
            }
        }
    }
        
     if (auth?.authUser) {
        return <Navigate to='/banner' replace />;
      }
            return (
                <>
                <section className="Login">

                    <div className="login-container">
                        <div className="login-content">
                            <div className="login-form">
                            <div className="login-logo">
                    <Stack mb={4}>
                        <img
                            src={DIYLogo}
                            alt='diylogo'
                            style={{alignSelf:'center', width:'210px',}}
                            />
                    </Stack>
                       </div>
                                <h2 className="login-title">Login</h2>
                                <TextBox
                                    id="loginEmail"
                                    placeholder="Email"
                                    prefix={<MailFilled />}
                                    onChange={(e) => setEmail(e.target.value)}
                                    errorMessage={emailError}
                                />
                                <br />
                                <TextBoxPassword
                                    id="loginPassword"
                                    placeholder="Password"
                                    prefix={<LockFilled />}
                                    onChange={(e) => setPassword(e.target.value)}
                                    errorMessage={passwordError}
                                />
                                <br />
                                <br />
                                    <div className="custom-link">
                                        <StyledLink href={`/auth/forgot-password`}>Forgot Password</StyledLink>
                                    </div>
                               
                                <Buttons 
                                    onClick={Login} 
                                    title="Sign In"
                                    disabled={(email && password)? false : true}
                                    />
                                <br/>   
                                <br/>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    );
}

export default LoginPage;
