import React from 'react';
import { Button } from 'antd';
import './Custom.css';

const DeleteButtons = (Props: any) => {
    return (
        <>
            <div className={
            Props.disabled == true ? 
            "custom-button-delete-no-hover"
            : "custom-button-delete"
        }
        >
                <Button block {...Props}> {Props.title} </Button>
            </div>
        </>
    )
}
export default DeleteButtons;
