import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { createCustomer, fetchCustomers, fetchCustomerById, updateCustomerById } from "../../redux/customer/reducer";
import { useEffect, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { customerSchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row, Typography } from "antd";
import { Toaster } from "../../components/common/Toaster/Toaster";

const { Text } = Typography;

const CustomerForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: any }>();

   //state data
   const [firstname, setFirstname] = useState("");
   const [lastname, setLastname] = useState("");
   const [email, setEmail] = useState("");
   const [mobile_no, setMobileNo] = useState("");
   const [landline_no, setLandlineNo] = useState("");
   const [isDirty, setIsDirty] = useState(false);


  //error State
  const [errors, setErrors] = useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    mobile_noError: "",
    landline_noError: "",
  });

    const defaultCustomer = useSelector((state: RootState) => state.storeCustomer.customers);

    const transactionStatus = useSelector(
        (state: RootState) => state.storeCustomer.transactionStatus
    );

    const stateError = useSelector((state: RootState) => state.storeCustomer.error);

    const customerData = {
        firstname,
        lastname,
        email,
        mobile_no,
        landline_no,
    };
    
    useEffect(() => {
      console.log("id"+id)
        if (id) {
          const numericId = parseInt(id, 10);
          dispatch(fetchCustomers());
          dispatch(fetchCustomerById(numericId));
          clearErrors();
        }
      }, [id, dispatch]);
    
      useEffect(() => {
        console.log("id"+id)
        if (id && defaultCustomer.length > 0) {
          const numericId = parseInt(id, 10);
          //dispatch(fetchBannerById(numericId));
          const selectedCustomer = defaultCustomer.find(
            (customer) => customer.id === numericId
          );
    
          if (selectedCustomer) {
            setFirstname(selectedCustomer.firstname || "");
            setLastname(selectedCustomer.lastname || "");
            setEmail(selectedCustomer.email || "");
            setMobileNo(selectedCustomer.mobile_no || "");
            setLandlineNo(selectedCustomer.landline_no || "");
          }
        }
      }, [defaultCustomer, id, dispatch]);

      useEffect(() => {
        setIsDirty(isFormDirty(customerData, defaultCustomer[0]));
      }, [customerData, defaultCustomer]);
    

      const clearErrors = () => {
        setErrors({
          firstnameError: "",
          lastnameError: "",
          emailError: "",
          mobile_noError: "",
          landline_noError: "",
        });
      };
  
      const handleCustomerForm = async () => {
        try {
         const schema = customerSchema;
          await schema.validate(customerData,{ abortEarly: false });
    
          const data = {
            id: defaultCustomer.length + 1,
            ...customerData,
          };
    
          await dispatch(createCustomer(data));
    
          if (stateError === "") {
            await dispatch(fetchCustomers());
            navigate(`/customer`, { replace: true });
            await Toaster("Successfully Added Customer", "success");
            clearErrors();
          } else {
            await Toaster(stateError, "error");
          }
        } catch (err: any) {
          if (err.inner) {
            handleSchemaError(err);
          } else {
            console.log(err);
          }
        }
      };

      const handleSchemaError = (err: any) => {
        if (err.inner) {
          Toaster("Transaction invalid, please review your inputs", "error");
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            err.inner.forEach((error: { path: string; message: string }) => {
              const errorField = (error.path + "Error") as keyof typeof prevErrors;
              if (errorField in newErrors) {
                newErrors[errorField] = error.message;
              }
            });
            return newErrors;
          });
        } else {
          console.error(err);
        }
      };
    
      const handleErrorChangeMobileNo = (
        field: string,
        value: string,
        fieldName: string
      ) => {
        const isOnlyDigits = /^\d*$/.test(value); // Regex to check if the value contains only digits
        const isValidLength = value.length <= 11; // Check if the length of the value is less than or equal to 11
        
        if (!isOnlyDigits) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: `${fieldName} should only contain numbers`,
          }));
        } else if (!isValidLength) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: `${fieldName} should not exceed 11 digits`,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
          }));
        }
      };
    
      const handleChangeMobileNo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setMobileNo(value);
        handleErrorChangeMobileNo('mobile_noError', value, 'Mobile No');
      };

      const handleErrorChangeLandlineNo = (
        field: string,
        value: string,
        fieldName: string
      ) => {
        const isOnlyDigits = /^\d*$/.test(value); // Regex to check if the value contains only digits
        const isValidLength = value.length <= 10; // Check if the length of the value is less than or equal to 11
        
        if (!isOnlyDigits) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: `${fieldName} should only contain numbers`,
          }));
        } else if (!isValidLength) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: `${fieldName} should not exceed 10 digits`,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
          }));
        }
      };
    
      const handleChangeLandlineNo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLandlineNo(value);
        handleErrorChangeLandlineNo('landline_noError', value, 'Landline No');
      };


    const handleErrorChange = (
      errorSetter: string,
      value: any,
      title: string
    ) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [errorSetter]: value ? "" : `${title} is required`,
      }));
    };
  
    const handleBackToMain = () => {
        navigate(`/customer`, { replace: true });
    };

    const handleUpdateCustomerById = async () => {
      try {
        const schema = customerSchema;
        await schema.validate(customerData, { abortEarly: false });
  
        const data = {
          ...customerData,
          id: parseInt(id),
          test: 123,
        };
  
        await dispatch(updateCustomerById(data));
  
        if (!stateError) {
          await dispatch(fetchCustomers());
          await Toaster("Successfully Updated Customer", "success");
          navigate(`/customer`, { replace: true });
          clearErrors();
        } else await Toaster(stateError, "error");
      } catch (err: any) {
        if (err.inner) {
          handleSchemaError(err);
        } else {
          console.log(err);
        }
      }
    };

    const isFormDirty = (customerData: any, defaultCustomer: any) => {
      if (defaultCustomer === undefined) return true;
  
      const fieldsToCompare = Object.keys(customerData);
      for (let field of fieldsToCompare) {
        if (customerData[field] !== defaultCustomer[field]) {
          return true;
        }
      }
      return false;
    }
      
    return (
        <>
          <ContentHeader 
             backLink={handleBackToMain}
                    title= {id ? "Update Customer" : "New Customer"}
                    btnTitle="Save"
                    EventClick={id ? handleUpdateCustomerById : handleCustomerForm}
                    Cancel={handleBackToMain}
                    Loading={transactionStatus === "loading" ? true : false}
                    // disabled={emailError ? true : false}
                       disabled={!isDirty}
                />
                 <h1>Customer Information</h1>

                 <Row gutter={16}>
                    <Col span={24} style={{paddingLeft: "0px", paddingBottom: "20px"}}>
                        <Row gutter={16}>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Firstname</label>
                                <TextBox
                                    style={{ width: '100%' }}
                                    value={firstname}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                        setFirstname(e.target.value); 
                                        handleErrorChange(
                                          "firstnameError",
                                          e.target.value,
                                          "First Name"
                                        );
                                        }}
                                      errorMessage={errors.firstnameError}
                                />
                            </Col>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Lastname</label>
                            <TextBox
                                    style={{ width: '100%' }}
                                    value={lastname}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                        setLastname(e.target.value); 
                                        handleErrorChange(
                                          "lastnameError",
                                          e.target.value,
                                          "Last Name"
                                        );
                                        }}
                                      errorMessage={errors.lastnameError}
                                />
                            </Col>
                            <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                            <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Email</label>
                            <TextBox
                                    style={{ width: '100%' }}
                                    value={email}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={(e) => {
                                        setEmail(e.target.value); 
                                        handleErrorChange(
                                          "emailError",
                                          e.target.value,
                                          "Email"
                                        );
                                        }}
                                      errorMessage={errors.emailError}
                                />
                            </Col>
                        </Row>
                    </Col>
                   
                    <Col span={24} style={{paddingLeft: "0px"}}>
                        <Row gutter={16}>
                            <Col span={18}>
                                <Row gutter={16}>
                                <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>
                                <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Mobile No</label>
                                <TextBox
                                    style={{ width: '100%' }}
                                    value={mobile_no}
                                    placeholder="..."
                                    prefix={" "}
                                    onChange={handleChangeMobileNo}
                                    errorMessage={errors.mobile_noError}
                                />
                                 </Col>
                                    <Col span={8} style={{paddingRight:"40px", paddingLeft: "0px"}}>  
                                        <label className='custom-label-select' style={{ display: 'block', marginLeft:"18px" }}>Landline No</label>
                                        <TextBox   
                                            style={{ width: '100%' }}
                                            value={landline_no}
                                            placeholder="..."
                                            prefix={" "}
                                            onChange={handleChangeLandlineNo}
                                            // onChange={(e) => {
                                            //     setLandlineNo(e.target.value); 
                                            //     handleErrorChange(
                                            //       "landline_noError",
                                            //       e.target.value,
                                            //       "Landline No"
                                            //     );
                                            // }}
                                              errorMessage={errors.landline_noError}
                                    />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    </>
    )

}

export default CustomerForm;
