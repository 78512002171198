import React from 'react';
import { Checkbox } from 'antd';

interface AppCheckBoxProps {
    onChange: any;
    title: string;
    value: any;
}


const AppCheckBox = (OwnProps: AppCheckBoxProps) => {
    return (
        <>
            <Checkbox 
            checked={OwnProps.value}
            onChange={OwnProps.onChange}  
            >{OwnProps.title}</Checkbox>
        </>
    )
} ;

export default AppCheckBox;