import { fetchUsers } from "../utils";
import { usersData } from "../components/constant";

export const loginUser = async (email: string, password: string) => {
    const response = await fetchUsers({   
        email: email,
        password: password}
    )
    return response;
  };
  
export const getLoggedInUser = async (id: number) => {
    if (!id) return null;
    const response = usersData.filter(user => user.id === id)
    return response[0];
  
  };