import React from 'react';
import { Select, Typography } from 'antd';
import './Custom.css';

const { Text } = Typography;

interface OwnProps {
    defaultValue?: string;
    placeholder?: string;
    onChange?: (value: any) => void;
    onSearch?: (value: string) => void;
    options?: any;
    errorMessage?: string;
    value?: any;
    [key: string]: any; // Additional props
}

const SelectDropdown: React.FC<OwnProps> = ({
    defaultValue,
    placeholder,
    onChange,
    onSearch,
    options,
    value,
    errorMessage,
    ...restProps
}) => {
    return (
        <>
            <Select
                defaultValue={defaultValue}
                value={value}
                style={{ minWidth: 200, width: 'auto' }} // Set a minimum width and allow it to adapt
                showSearch
                placeholder={placeholder}
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={options}
                status={errorMessage ? 'error' : ''}
                {...restProps}
                
            />
            <br /><br />
            {errorMessage && (
                <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                    {errorMessage}
                </Text>
            )}
        </>
    );
}

export default SelectDropdown;
