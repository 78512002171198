import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  createStore,
  fetchStores,
  fetchStoreById,
  updateStoreById,
  setStoreListFromMap,
} from "../../redux/store/reducer";
import { useEffect, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { storeSchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row } from "antd";
import { Toaster } from "../../components/common/Toaster/Toaster";
import { RadarChartOutlined } from "@ant-design/icons";
import MapComponent from "../../components/common/Map/Map";

const StoreForm = () => {
  const [createdAt, setCreatedAt] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: any }>();

  const defaultStore = useSelector(
    (state: RootState) => state.storeStore.formStore
  );

  //state data
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [code, setCode] = useState("");
  const [city, setCity] = useState("");

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");


  const [storeData, setStoredata] = useState<
    {
      name: string;
      address: string;
      code: string;
      city: string;
      longitude: string;
      latitude: string;
    }[]
  >([]);

  //error State
  const [errors, setErrors] = useState({
    nameError: "",
    addressError: "",
    codeError: "",
    cityError: "",
    longitudeError: "",
    latitudeError: "",
  });

  const transactionStatus = useSelector(
    (state: RootState) => state.storeStore.transactionStatus
  );
  const stateError = useSelector((state: RootState) => state.storeStore.error);

  useEffect(() => {
    setStoredata([
      {
        name: name,
        address: address,
        code: code ? code : defaultStore[0]?.code ?? "",
        city: city ? city : defaultStore[0]?.city ?? "",
        longitude: defaultStore[0]?.longitude ?? "",
        latitude: defaultStore[0]?.latitude ?? "",
      },
    ]);

    console.log("STORE DATA:", storeData);
    
    handleErrorChange("cityError", defaultStore[0]?.city ?? "default", "City");
    handleErrorChange(
      "codeError",
      defaultStore[0]?.zipcode ?? "default",
      "Code"
    );
    handleErrorChange(
      "longitudeError",
      defaultStore[0]?.longitude ?? "default",
      "Longitude"
    );
    handleErrorChange(
      "latitudeError",
      defaultStore[0]?.latitude ?? "default",
      "Latitude"
    );
  }, [defaultStore, name, address, code, city]);

  useEffect(() => {
    console.log("id" + id);
    if(id) {
      const numericId = parseInt(id, 10);
      dispatch(fetchStores());
      dispatch(fetchStoreById(numericId));
      clearErrors();
    }
  }, [id, dispatch]);

  const clearErrors = () => {
    setErrors({
      nameError: "",
      addressError: "",
      codeError: "",
      cityError: "",
      longitudeError: "",
      latitudeError: "",
    });
  };

  useEffect(() => {
    if (id && defaultStore.length > 0) {
      const numericId = parseInt(id, 10);
      //dispatch(fetchStoreById(numericId));
      const selectedStore = defaultStore.find(
        (store) => store.id === numericId
      );

      if (selectedStore) {
        setName(selectedStore.name || "");
        setAddress(selectedStore.address || "");
        setCode(selectedStore.code || "");
        setCity(selectedStore.city || "");
        setLongitude(selectedStore.longitude || "");
        setLatitude(selectedStore.latitude || "");
        setCreatedAt(selectedStore.createdAt || "");
      }
    }
  }, [defaultStore, id, dispatch]);

  const handleStoreForm = async () => {
    try {
      const schema = storeSchema;
      await schema.validate(storeData[0], { abortEarly: false });
      console.log(storeData[0]);
      const data = {
        id: defaultStore.length + 1,
        ...storeData[0],
      };

      await dispatch(createStore(data));

      if (stateError === "") {
        await dispatch(fetchStores());
        navigate(`/store`, { replace: true });
        await Toaster("Successfully Added Store", "success");
        await dispatch(setStoreListFromMap([]))
        clearErrors();
      } else {
        await Toaster(stateError, "error");
      }
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
        console.log(storeData[0]);
      } else {
        console.log(err);
      }
    }
  };

  const handleSchemaError = (err: any) => {
    console.log(err.inner);
    if (err.inner) {
      Toaster("Transaction invalid, please review your inputs", "error");
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        err.inner.forEach((error: { path: string; message: string }) => {
          const errorField = (error.path + "Error") as keyof typeof prevErrors;
          if (errorField in newErrors) {
            newErrors[errorField] = error.message;
          }
        });
        return newErrors;
      });
    } else {
      console.error(err);
    }
  };

  const handleErrorChange = (
    errorSetter: string,
    value: any,
    title: string
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorSetter]: value ? "" : `${title} is required`,
    }));
  };

  const handleBackToMain = async () => {
    await dispatch(setStoreListFromMap([]))
    navigate(`/store`, { replace: true });

    //window.location.replace("/store");
  };

  const handleUpdateStoreById = async () => {
    try {
      const schema = storeSchema;
      await schema.validate(storeData[0], { abortEarly: false });

      const data = {
        ...storeData[0],
        id: parseInt(id),
        test: 123,
      };

      await dispatch(updateStoreById(data));

      if (!stateError) {
        await dispatch(fetchStores());
        await Toaster("Successfully Updated Store", "success");
        await dispatch(setStoreListFromMap([]))
        navigate(`/store`, { replace: true });
        clearErrors();
      } else await Toaster(stateError, "error");
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
        console.log(err);
      } else {
        console.log(err);
      }
    }
  };

  
  return (
    <>
      <ContentHeader
        backLink={handleBackToMain}
        title={id ? "Update Store" : "New Store"}
        btnTitle="Save"
        EventClick={id ? handleUpdateStoreById : handleStoreForm}
        Cancel={handleBackToMain}
        Loading={transactionStatus === "loading" ? true : false}
        //disabled={emailError ? true : false}
      />
      <h1>Store Information</h1>
      <p>Last Updated: {createdAt}</p>
      <Row gutter={24}>
        <Col span={10}>
          <Row gutter={24}>
            <Col span={24} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                Name
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={name}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setName(e.target.value);
                  handleErrorChange("nameError", e.target.value, "Name");
                }}
                errorMessage={errors.nameError}
              />
              <br />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <MapComponent
                //mapContainerStyle={{ height: "300px", width: "100%" }}
                defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
              ></MapComponent>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row gutter={24}>
            <Col span={24} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                Address
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={address}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setAddress(e.target.value);
                  handleErrorChange("addressError", e.target.value, "Address");
                }}
                errorMessage={errors.addressError}
              />
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                Code
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={code !== "" ? code : defaultStore[0]?.code}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setCode(e.target.value);
                  handleErrorChange("codeError", e.target.value, "Code");
                }}
                errorMessage={errors.codeError}
              />
              <br />
            </Col>
            <Col span={12} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                City
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={city !== "" ? city : defaultStore[0]?.city}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setCity(e.target.value);
                  handleErrorChange("cityError", e.target.value, "City");
                }}
                errorMessage={errors.cityError}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                <RadarChartOutlined /> Latitude
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={latitude !== "" ? latitude : defaultStore[0]?.latitude}
                placeholder="..."
                prefix={" "}
                readOnly
                onChange={(e) => {
                  setLatitude(e.target.value);
                  handleErrorChange(
                    "latitudeError",
                    e.target.value,
                    "Latitude"
                  );
                }}
                errorMessage={errors.latitudeError}
              />
            </Col>
            <Col span={12} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                <RadarChartOutlined /> Longitude
              </label>
              <TextBox
                style={{ width: "100%" }}
                value={
                  longitude !== "" ? longitude : defaultStore[0]?.longitude
                }
                readOnly
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setLongitude(e.target.value);
                  handleErrorChange(
                    "longitudeError",
                    e.target.value,
                    "Longitude"
                  );
                }}
                errorMessage={errors.longitudeError}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StoreForm;
