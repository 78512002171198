
import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { APIurl } from '../../utils/apiUrl';

// Define the shape of user
    interface Customer { 
    id: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile_no?: string;
    landline_no?: string;
    [key: string]: any; // Add other user properties here
}

// Define the shape of the initial state
interface CustomerState {
    customers: Customer[];
    customerstoDelete: React.Key[];
    searchText: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
    selectedRow: number;
}

const initialState: CustomerState = {
    customers: [],
    customerstoDelete: [],
    searchText: '',
    status: 'idle',
    transactionStatus: '',
    error: '',
    selectedRow: 0,
}

// Create the async thunk
export const fetchCustomers = createAsyncThunk('customer/fetchCustomers', async () => {
    const response = await axios.get(`${APIurl}/api/customers`);
    return response.data;
  });

export const fetchCustomerById = createAsyncThunk(
    'customer/fetchCustomerById',
    async (id: number) => {
        const response = await axios.get(`${APIurl}/api/customers/${id}`);
        return response.data;
    }
);

export const updateCustomerById = createAsyncThunk(
    'customer/updateCustomerById',
    async (customer: Customer) => {
        console.log(customer)
        const response = await axios.put(`${APIurl}/api/customers/${customer.id}`, customer);
        return response.data; // Assuming the API returns the updated customers object
    }
);

export const deleteMany = createAsyncThunk(
    'customer/deleteMany',
    async (customer: React.Key[]) => {
        const response = await axios.post(`${APIurl}/api/customers/delete-many`, customer);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const createCustomer = createAsyncThunk(
    'customer/createCustomer',
    async (customer: Customer) => {
        const response = await axios.post(`${APIurl}/api/customers`, customer);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const deleteCustomerById = createAsyncThunk(
    'customer/deleteCustomerById',
    async (id: number) => {
        const response = await axios.delete(`${APIurl}/api/customers/${id}`);
        return response.data; // Return the ID of the deleted banner
    }
);

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload;
        },
        setCustomerListsToDelete(state, action: PayloadAction<React.Key[]>) {
            state.customerstoDelete = action.payload;
        },
        setDeleteCustomerLists(state, action: PayloadAction<React.Key[]>) {
            const idsToDelete = action.payload;
            state.customers = state.customers.filter(customer => !idsToDelete.includes(customer.id));
        },
        setSelectedRow(state, action: PayloadAction<number>) {
            state.selectedRow = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customers = action.payload;
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch customers';
            })
            .addCase(createCustomer.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(createCustomer.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.customers.push(action.payload);
            })
            .addCase(createCustomer.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to create customers, please contact your administrator';
            })
            .addCase(fetchCustomerById.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(updateCustomerById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(updateCustomerById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                 state.customers = action.payload;
                 state.error = '';
            })
            .addCase(updateCustomerById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update customers, please contact your administrator';
            })
            .addCase(deleteMany.pending, (state) => {
            state.transactionStatus = 'loading';
            })
            .addCase(deleteMany.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.customers = action.payload;
                state.error = '';
            })
            .addCase(deleteMany.rejected, (state, action) => {
                state.transactionStatus = 'failed'
                state.error = action.error.message ?? 'Failed to delete customers, please contact your administrator';
            })
            .addCase(deleteCustomerById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(deleteCustomerById.fulfilled, (state, action) => {
                state.customers = action.payload;
                state.error = '';
                state.transactionStatus = 'succeeded';
            })
            .addCase(deleteCustomerById.rejected, (state, action) => {
                state.error = action.error.message ?? 'Failed to delete customers, please contact your administrator';
                state.transactionStatus = 'failed';
            });
    },

});

export const { 
    setSearchText,
    setCustomerListsToDelete,
    setDeleteCustomerLists,
    setSelectedRow
} = customerSlice.actions;

export const selectCustomers = (state: RootState) => state.storeCustomer.customers;
export const selectSearchText = (state: RootState) => state.storeCustomer.searchText;

export const selectFilteredCustomers = createSelector(
    [selectCustomers, selectSearchText],
    (customers: Customer[], searchText: string) => {
        if (!searchText) {
            return customers; // Return all customers if no searchText provided
        }
        const normalizedSearchText = searchText.toLowerCase();
        
        return customers.filter(customer =>
            (customer.firstname?.toLowerCase().includes(normalizedSearchText) || 
             customer.lastname?.toLowerCase().includes(normalizedSearchText) || 
             customer.email?.toLowerCase().includes(normalizedSearchText) ||
             customer.mobile_no?.toLowerCase().includes(normalizedSearchText) ||
             customer.landline_no?.toLowerCase().includes(normalizedSearchText) 
           )
        );
    }
);

export default customerSlice.reducer;
