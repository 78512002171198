import React from 'react';
import { DatePicker, Typography } from 'antd';
import dayjs from 'dayjs';
const { Text } = Typography;

const AppDatePicker = (OwnProps: any) => {
    return (
        <>
            <DatePicker 
                
                value={OwnProps.value ? dayjs(OwnProps.value) : null} 
                disabled={OwnProps.disabled}
                placeholder={OwnProps.placeholder}
                onChange={OwnProps.onChange} 
                status={OwnProps.errorMessage ? 'error' : ''}
            />
            <br /><br />
            {OwnProps.errorMessage && (
                <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                    {OwnProps.errorMessage}
                </Text>
            )}
        </>
    )
}

export default AppDatePicker;
