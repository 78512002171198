import React, { useState } from 'react'
import { MailFilled, LeftCircleOutlined } from '@ant-design/icons';
import Buttons from '../../components/Buttons/Buttons';
import { Stack,} from '@mui/material';
import TextBox from "../../components/common/LoginForms/TextBox"
import './Custom.css';
import Styled from 'styled-components';
import DIYLogo from '../../assets/images/diy_main_logo.png';
import { useNavigate } from 'react-router-dom';
import AppModalNotifications from "../../components/Modals/AppModalNotifcation";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleResetPassword = () => {
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }
        setEmailError(''); // Clear any previous errors
        setModalOpen(true);

        setTimeout(() => {
            navigate(`/auth/reset-password`, {replace: true });
        }, 3000); // Adjust timeout as needed
    };


    const StyledLink = Styled.a`
    color:black;
    text-decoration:none;
    &:hover {
    color:#none;}`;
  return (
    <>
    <section className="ForgotPassword">
        <div className="forgot-password-container">
        <div className="login-logo">
            <Stack mb={4}>
                <img
                    src={DIYLogo}
                    alt='diylogo'
                    style={{alignSelf:'center', width:'210px',}}
                    />
                    </Stack>
                </div>
                <div className="forgot-password-content">
                <div className="forgot-logo"></div>
                <div className="forgot-form">
                        <h2 className="forgot-title">
                             <StyledLink href={`/auth`}><LeftCircleOutlined/></StyledLink> Forgot Password 
                        </h2>
                    <p className='custom-label'>Please enter your email address to receive a password reset link.</p>
                        <TextBox placeholder="Email"  
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errorMessage={emailError}
                        prefix={<MailFilled />} />
                    <br/>
                    <AppModalNotifications 
                        title = "Email Sent"
                        contents = "Check your email to find the reset link."
                        isModalOpen = {modalOpen}
                        handleOk = {() => setModalOpen(false)}
                        onCancel = {() => navigate(`/auth`, { replace: true })}
                    />
                    <Buttons onClick={handleResetPassword} title="Submit"
                     disabled={(email)? false : true}/>  
                </div>
            </div>
        </div>  
    </section>
</>
  )
}

export default ForgotPassword