import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  createBanner,
  fetchBanners,
  fetchBannerById,
  updateBannerById,
} from "../../redux/banner/reducer";
import { SetStateAction, useEffect, useRef, useState } from "react";
import TextBox from "../../components/common/GlobalForms/TextBox";
import { bannerSchema, bannerWithExpirySchema } from "./utils/schema";
import ContentHeader from "../../components/common/layout-ui/ContentHeader";
import { Col, Row, Typography } from "antd";
import { bannerCategory } from "../../components/constant";
import SelectDropdown from "../../components/common/GlobalForms/Select";
import TextBigArea from "../../components/common/GlobalForms/TextBigArea";
import Buttons from "../../components/Buttons/Buttons";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import AppDatePicker from "../../components/common/GlobalForms/AppDatePicker";
import AppCheckBox from "../../components/common/GlobalForms/AppCheckBox";
import AppRadio from "../../components/common/GlobalForms/AppRadio";
import { Toaster } from "../../components/common/Toaster/Toaster";

const { Text } = Typography;

const BannerForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: any }>();

  //state data
  const [title, setTitle] = useState("");
  const [image, setImage] = useState<any | null>(null);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState<number | null>(null);
  const [category, setCategory] = useState<number | null>(null);
  const [noExpiration, setNoExpiration] = useState<boolean | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  //error State
  const [errors, setErrors] = useState({
    descriptionError: "",
    imageError: "",
    titleError: "",
    startDateError: "",
    endDateError: "",
    statusError: "",
    categoryError: "",
  });

  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const defaultBanner = useSelector(
    (state: RootState) => state.storeBanner.banners
  );
  const transactionStatus = useSelector(
    (state: RootState) => state.storeBanner.transactionStatus
  );
  const stateError = useSelector((state: RootState) => state.storeBanner.error);

  const bannerData = {
    image,
    title,
    description,
    startDate,
    endDate,
    noExpiration,
    status,
    category,
  };

  const bannerCategories = bannerCategory.map((data) => ({
    value: data.id,
    label: data.description,
  }));

  useEffect(() => {
    if (id) {
      const numericId = parseInt(id, 10);
      //dispatch(fetchBanners());
      dispatch(fetchBannerById(numericId));
      clearErrors();
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && defaultBanner.length > 0) {
      const numericId = parseInt(id, 10);
      //dispatch(fetchBannerById(numericId));
      const selectedBanner = defaultBanner.find(
        (banner) => banner.id === numericId
      );
      
      if (selectedBanner) {
        setDescription(selectedBanner.description || "");
        setImage(selectedBanner.image || "");
        setTitle(selectedBanner.title || "");
        setStartDate(selectedBanner.startDate || "");
        setEndDate(selectedBanner.endDate || "");
        setNoExpiration(selectedBanner.noExpiration || false);
        setStatus(selectedBanner.status || 0);
        setCategory(selectedBanner.category || null);
        setBackgroundImage(`url('${selectedBanner.image}')`);
      }
    }
  }, [defaultBanner, id, dispatch]);

  useEffect(() => {
    setIsDirty(isFormDirty(bannerData, defaultBanner[0]));
  }, [bannerData, defaultBanner]);

  const hangleBannerForm = async () => {
    try {
      const schema = !noExpiration ? bannerSchema : bannerWithExpirySchema;
      await schema.validate(bannerData, { abortEarly: false });

      const data = {
        id: defaultBanner.length + 1,
        ...bannerData,
      };

      await dispatch(createBanner(data));

      if (stateError === "") {
        await dispatch(fetchBanners());
        navigate(`/banner`, { replace: true });
        await Toaster("Successfully Added Banner", "success");
        clearErrors();
      } else {
        await Toaster(stateError, "error");
      }
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
      } else {
        console.log(err);
      }
    }
  };
  const clearErrors = () => {
    setErrors({
      descriptionError: "",
      imageError: "",
      titleError: "",
      startDateError: "",
      endDateError: "",
      statusError: "",
      categoryError: "",
    });
  };

  const handleupdateBannerById = async () => {
    try {
      const schema = !noExpiration ? bannerSchema : bannerWithExpirySchema;
      await schema.validate(bannerData, { abortEarly: false });

      const data = {
        ...bannerData,
        id: parseInt(id),
        test: 123,
      };

      await dispatch(updateBannerById(data));

      if (!stateError) {
        await dispatch(fetchBanners());
        await Toaster("Successfully Updated Banner", "success");
        navigate(`/banner`, { replace: true });
        clearErrors();
      } else await Toaster(stateError, "error");
    } catch (err: any) {
      if (err.inner) {
        handleSchemaError(err);
      } else {
        console.log(err);
      }
    }
  };

  const handleSchemaError = (err: any) => {
    if (err.inner) {
      Toaster("Transaction invalid, please review your inputs", "error");
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        err.inner.forEach((error: { path: string; message: string }) => {
          const errorField = (error.path + "Error") as keyof typeof prevErrors;
          if (errorField in newErrors) {
            newErrors[errorField] = error.message;
          }
        });
        return newErrors;
      });
    } else {
      console.error(err);
    }
  };

  const handleUploadButton = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setImage(event.target.files?.item(0));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uploadedImageUrl = reader.result as string;
        setBackgroundImage(`url(${uploadedImageUrl})`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBackToMain = () => {
    navigate(`/banner`, { replace: true });
  };

  const handleErrorChange = (
    errorSetter: string,
    value: any,
    title: string
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorSetter]: value ? "" : `${title} is required`,
    }));
  };

  const isFormDirty = (bannerData: any, defaultBanner: any) => {
    if (defaultBanner === undefined) return true;

    const fieldsToCompare = Object.keys(bannerData);
    for (let field of fieldsToCompare) {
      if (bannerData[field] !== defaultBanner[field]) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ContentHeader
        backLink={handleBackToMain}
        title={id ? "Update Promo Banner" : "New Promo Banner"}
        btnTitle="Save"
        EventClick={id ? handleupdateBannerById : hangleBannerForm}
        cancelPopconfirm={{
          title: "Cancel",
          description: "Are you sure you want to cancel? Your changes won’t be saved.",
          okText: "Yes",
          cancelText: "No",
          onConfirm: handleBackToMain // Function to handle navigation
        }}  
        Loading={transactionStatus === "loading" ? true : false}
        disabled={!isDirty || !(image || title || description || startDate ||
          endDate || noExpiration || status)}
      />
      <h1>Banner Information</h1>

      <Row gutter={16}>
        <Col span={24} style={{ paddingLeft: "0px", paddingBottom: "20px" }}>
          <Row gutter={16}>
            <Col span={12} style={{ paddingRight: "40px", paddingLeft: "0px" }}>
              <label
                className="custom-label-select"
                style={{ display: "block", marginLeft: "18px" }}
              >
                Banner Title
              </label>

              <TextBox
                style={{ width: "100%" }}
                value={title}
                placeholder="..."
                prefix={" "}
                onChange={(e) => {
                  setTitle(e.target.value);
                  handleErrorChange("titleError", e.target.value, "Title");
                }}
                errorMessage={errors.titleError}
              />
            </Col>
            <Col span={12}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                Banner Category
              </label>
              <SelectDropdown
                placeholder={"..."}
                defaultValue={""}
                value={category}
                options={bannerCategories}
                style={{ width: "100%" }}
                onChange={(value: SetStateAction<any>) => {
                  setCategory(value);
                }}
                errormessage={errors.categoryError}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingLeft: "10px" }}>
          <Row gutter={16}>
            <Col span={24}>
              <label
                className="custom-label-select"
                style={{ display: "block" }}
              >
                Banner Description
              </label>
              <TextBigArea
                rows={2}
                value={description}
                placeholder={"..."}
                style={{ width: "100%" }}
                onChange={(e: {
                  target: { value: SetStateAction<string> };
                }) => {
                  setDescription(e.target.value);
                  handleErrorChange(
                    "descriptionError",
                    e.target.value,
                    "Description"
                  );
                }}
                errormessage={errors.descriptionError}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingLeft: "10px", paddingTop: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <label className="custom-label-select">Banner Image</label>
              <br />
              <div
                className="image-container"
                style={{ borderColor: errors.imageError ? "#ff4d4f" : "#ccc" }}
              >
                <div
                  className="image-preview"
                  style={{ backgroundImage: backgroundImage || "none" }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={(event) => {
                      handleFileUpload(event);
                      handleErrorChange("imageError", event, "Image");
                    }}
                  />
                  <Buttons
                    title="Upload Image"
                    type="file"
                    onClick={handleUploadButton}
                    style={{ width: "auto" }}
                    icon={<UploadOutlined />}
                  >
                    {" "}
                  </Buttons>
                </div>
              </div>
              <br></br>
              {errors.imageError && (
                <Text type="danger" style={{ float: "left", fontSize: "12px" }}>
                  {errors.imageError}
                </Text>
              )}
            </Col>
            <Col span={12}>
              <ul>
                <li>
                  Recommended image resolution is minimum of{" "}
                  <strong>400px</strong> by <strong>400px</strong>.
                </li>
                <li>
                  Image file size should be no more than <strong>2mb</strong>.
                </li>
                <li>
                  Allowed image formats are <strong>.jpg</strong> and{" "}
                  <strong>.png</strong> only.
                </li>
              </ul>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingLeft: "10px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <h3>Promo Banner</h3>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={18}>
              <Row gutter={16}>
                <Col span={6}>
                  <label
                    className="custom-label-select"
                    style={{ display: "block" }}
                  >
                    Start Date
                  </label>
                  <br></br>
                  <AppDatePicker
                    value={startDate}
                    placeholder="----/----"
                    onChange={(date: any, dateString: any) => {
                      setStartDate(dateString);
                      handleErrorChange("startDateError", date, "Start Date");

                      if (moment(dateString).isSameOrBefore(endDate)) {
                        handleErrorChange("startDateError", date, "Start Date");
                      } else if (!noExpiration && endDate && dateString)
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["startDateError"]:
                            "Start date must be less than End date",
                        }));
                    }}
                    errorMessage={errors.startDateError}
                  />
                </Col>
                <Col span={6}>
                  <label
                    className="custom-label-select"
                    style={{ display: "block" }}
                  >
                    End Date
                  </label>
                  <br></br>
                  <AppDatePicker
                    value={endDate}
                    placeholder="--/--/----"
                    onChange={(date: any, dateString: any) => {
                      setEndDate(dateString);
                      handleErrorChange("endDateError", date, "End Date");

                      if (
                        startDate &&
                        moment(startDate).isSameOrBefore(dateString)
                      ) {
                        handleErrorChange("startDateError", date, "Start Date");
                      } else if (!noExpiration && dateString && startDate)
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ["startDateError"]:
                            "Start date must be less than End date",
                        }));
                    }}
                    errorMessage={errors.endDateError}
                    disabled={noExpiration}
                  />
                </Col>
                <Col span={4}>
                  <br></br>
                  <br></br>
                  <AppCheckBox
                    title="No Expiration"
                    value={noExpiration}
                    onChange={(e: any) => {
                      setNoExpiration(e.target.checked);
                      setEndDate(noExpiration ? endDate : "");
                      handleErrorChange(
                        "endDateError",
                        e.target.checked ? e.target.checked : "",
                        "End Date"
                      );
                      handleErrorChange(
                        "startDateError",
                        (errors.startDateError !== '' && startDate ) ?  errors.startDateError : true,
                        "Start Date"
                      );
                    }}
                  />
                </Col>
                <Col span={8}>
                  <label
                    className="custom-label-select"
                    style={{ display: "block" }}
                  >
                    Status
                  </label>
                  <br></br>
                  <AppRadio
                    options={[
                      { value: 1, label: "Active" },
                      { value: 0, label: "Inactive" },
                    ]}
                    value={status}
                    onChange={(e: any) => {
                        
                      setStatus(e.target.value);
                      handleErrorChange(
                        "statusError",
                        true,
                        "Status"
                      );
                    }}
                    errorMessage={errors.statusError}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BannerForm;

