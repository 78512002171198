

import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { APIurl } from '../../utils/apiUrl';
// Define the shape of user
    interface User { 
    id: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    role?: string;
    status?: any;
    [key: string]: any; // Add other user properties here
}
 
// Define the shape of the initial state
interface UserState {  
    users: User[];
    userstoDelete: React.Key[];
    searchText: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
    transactionStatus: string;
    selectedRow: number;
}

const initialState: UserState = {
    users: [],
    userstoDelete: [],
    searchText: '',
    status: 'idle',
    transactionStatus: '',
    error: '',
    selectedRow: 0,
}

// Create the async thunk
export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
    const response = await axios.get(`${APIurl}/api/users`);
    return response.data;
  });

export const fetchUserById = createAsyncThunk(
    'user/fetchUserById',
    async (id: number) => {
        const response = await axios.get(`${APIurl}/api/users/${id}`);
        return response.data;
    }
);

export const updateUserById = createAsyncThunk(
    'user/updateUserById',
    async (user: User) => {
        console.log(user)
        const response = await axios.put(`${APIurl}/api/users/${user.id}`, user);
        return response.data; // Assuming the API returns the updated users object
    }
);

export const deleteMany = createAsyncThunk(
    'user/deleteMany',
    async (user: React.Key[]) => {
        const response = await axios.post(`${APIurl}/api/users/delete-many`, user);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const createUser = createAsyncThunk(
    'user/createUser',
    async (user: User) => {
        const response = await axios.post(`${APIurl}/api/users`, user);
        return response.data; // Assuming the API returns the updated banner object
    }
);

export const deleteUserById = createAsyncThunk(
    'user/deleteUserById',
    async (id: number) => {
        const response = await axios.delete(`${APIurl}/api/users/${id}`);
        return response.data; // Return the ID of the deleted banner
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload;
        },
        setUserListsToDelete(state, action: PayloadAction<React.Key[]>) {
            state.userstoDelete = action.payload;
        },
        setDeleteUserLists(state, action: PayloadAction<React.Key[]>) {
            const idsToDelete = action.payload;
            state.users = state.users.filter(user => !idsToDelete.includes(user.id));
        },
        setSelectedRow(state, action: PayloadAction<number>) {
            state.selectedRow = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ?? 'Failed to fetch users';
            })
            .addCase(createUser.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.users.push(action.payload);
            })
            .addCase(createUser.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to create users, please contact your administrator';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.users = action.payload;
            })
            .addCase(updateUserById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(updateUserById.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                 state.users = action.payload;
                 state.error = '';
            })
            .addCase(updateUserById.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to update users, please contact your administrator';
            })
            .addCase(deleteMany.pending, (state) => {
            state.transactionStatus = 'loading';
            })
            .addCase(deleteMany.fulfilled, (state, action) => {
                state.transactionStatus = 'succeeded';
                state.users = action.payload;
                state.error = '';
            })
            .addCase(deleteMany.rejected, (state, action) => {
                state.transactionStatus = 'failed';
                state.error = action.error.message ?? 'Failed to delete users, please contact your administrator';
            })
            .addCase(deleteUserById.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(deleteUserById.fulfilled, (state, action) => {
                state.users = action.payload;
                state.error = '';
                state.transactionStatus = 'succeeded';
            })
            .addCase(deleteUserById.rejected, (state, action) => {
                state.error = action.error.message ?? 'Failed to delete users, please contact your administrator';
                state.transactionStatus = 'failed';
            });
    },

});

export const { 
    setSearchText,
    setUserListsToDelete,
    setDeleteUserLists,
    setSelectedRow
} = userSlice.actions;

export const selectUsers = (state: RootState) => state.storeUser.users;
export const selectSearchText = (state: RootState) => state.storeUser.searchText;

export const selectFilteredUsers = createSelector(
    [selectUsers, selectSearchText],
    (users: User[], searchText: string) => {
        if (!searchText) {
            return users; // Return all users if no searchText provided
        }
        
        const normalizedSearchText = searchText.toLowerCase();
        
        return users.filter(user =>
            (user.firstname?.toLowerCase().includes(normalizedSearchText) || 
             user.lastname?.toLowerCase().includes(normalizedSearchText) || 
             user.email?.toLowerCase().includes(normalizedSearchText) || 
            (user.status === 1 ? "ACTIVE" : "INACTIVE").toLowerCase().includes(normalizedSearchText)
           )
        );
    }
);

export default userSlice.reducer;
