import React from 'react';
import Main from '../../components/common/layout-ui/Main';
import SideBar from '../../components/common/Sidebar/SideBar';
import Contents from '../../components/common/layout-ui/Content';
import { Outlet } from 'react-router-dom';
import LayoutContextProvider from '../../components/common/layout-ui/LayoutContext';
import { Layout } from 'antd';
import HeaderMain from '../../components/common/layout-ui/Header';

const AppContent = () => {

    return (
        <div>
            <LayoutContextProvider>
                <Layout>
                    <SideBar />
                    <Layout >
                        <HeaderMain />
                            <Contents>
                                <Main>
                                    <Outlet />
                                </Main>
                            </Contents>
                    </Layout>
                </Layout>
            </LayoutContextProvider>
        </div>
    )
}

export default AppContent;